import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { debounce } from 'lodash'
import React from 'react'
import { Alert, Form, FormControl } from 'react-bootstrap'
import * as yup from 'yup'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../../../../hooks/useNotyf'
import companyService from '../../../../../lib/services/companyService'
import { updateCurrentCompany } from '../../../../../redux/slices/session'
import { CompanyScheduleCallUrlsItem } from '../../../../../types/responses/companies'
import CompleteRegistrationFormikDirtyNavigationGuard from '../../components/CompleteRegistrationFormikDirtyNavigationGuard'

interface UpdateScheduleCallUrlParams {
  scheduleCompanyCallUrl: string
  scheduleClientCallUrl: string
}

const MeetingLink: React.FC = () => {
  const company = useCurrentCompanyOrFail()

  const debouncedCallback = debounce((callback: () => void) => {
    callback()
  }, 300)

  const dispatch = useAppDispatch()

  const notyf = useNotyf()

  const mutation = useMutation({
    mutationFn: async (
      params: UpdateScheduleCallUrlParams,
    ): Promise<CompanyScheduleCallUrlsItem> => {
      return companyService.updateScheduleCallUrls(company.id, params)
    },
    onSuccess: async (data: CompanyScheduleCallUrlsItem) => {
      dispatch(updateCurrentCompany(data))
    },
    onError: async () => {
      notyf.error('Failed to save URL. Please try again.')
    },
  })

  return (
    <div className="pop-in h-100 d-flex flex-column align-items-stretch justify-content-center text-start">
      <Alert className="mb-5 d-flex justify-content-center" variant="light">
        <div className="p-3 text-center">
          <p className="text-center">
            Please provide a link to a calendar that professional referral
            partners can use to book a call with you.
          </p>

          <p className="text-center mb-0">
            E.g., services like Calendly. If you don't have an account already,
            create one for free at{' '}
            <a href="https://calendly.com" target="_blank" rel="noreferrer">
              calendly.com
            </a>
            .
          </p>
        </div>
      </Alert>

      <Formik
        initialValues={{
          scheduleCompanyCallUrl: company.scheduleCompanyCallUrl ?? '',
          scheduleClientCallUrl: company.scheduleClientCallUrl ?? '',
        }}
        enableReinitialize
        validateOnChange
        validationSchema={yup.object().shape({
          scheduleCompanyCallUrl: yup
            .string()
            .url('Must be a valid URL')
            .nullable(),
          scheduleClientCallUrl: yup
            .string()
            .url('Must be a valid URL')
            .nullable(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await mutation.mutateAsync(values)

          setSubmitting(false)
        }}
      >
        {({ submitForm, errors, touched }) => {
          return (
            <>
              <CompleteRegistrationFormikDirtyNavigationGuard />

              <Form className="text-center" noValidate>
                <div className="mb-5">
                  <label className="h4 fw-bolder">
                    Link for meetings with clients
                  </label>

                  <Field
                    name="scheduleClientCallUrl"
                    className={classNames(
                      'form-control form-control-lg text-center',
                      {
                        'is-invalid':
                          errors.scheduleClientCallUrl &&
                          touched.scheduleClientCallUrl,
                      },
                    )}
                    onInput={() => debouncedCallback(submitForm)}
                  />

                  {errors.scheduleClientCallUrl &&
                  touched.scheduleClientCallUrl ? (
                    <FormControl.Feedback type="invalid">
                      {errors.scheduleClientCallUrl}
                    </FormControl.Feedback>
                  ) : null}
                </div>

                <div className="mb-5">
                  <label className="h4 fw-bolder">
                    Link for meetings with other professionals
                  </label>

                  <Field
                    name="scheduleCompanyCallUrl"
                    className={classNames(
                      'form-control form-control-lg text-center',
                      {
                        'is-invalid':
                          errors.scheduleCompanyCallUrl &&
                          touched.scheduleCompanyCallUrl,
                      },
                    )}
                    onInput={() => debouncedCallback(submitForm)}
                  />

                  {errors.scheduleCompanyCallUrl &&
                  touched.scheduleCompanyCallUrl ? (
                    <FormControl.Feedback type="invalid">
                      {errors.scheduleCompanyCallUrl}
                    </FormControl.Feedback>
                  ) : null}
                </div>
              </Form>

              {mutation.isSuccess && (
                <Alert variant="success" className="p-2 mt-3 align-self-center">
                  <p className="fs-3 mb-0">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-success"
                    />{' '}
                    Saved
                  </p>
                </Alert>
              )}
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default MeetingLink
