import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@rq-ratings/pro-solid-svg-icons'
import type { NotyfNotification } from 'notyf'
import { INotyfNotificationOptions, INotyfOptions, Notyf } from 'notyf'
import React, { useContext } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { TOAST_AUTOCLOSE_DELAY } from '../lib/constants'
import { useCaptureErrorShownToUserEvent } from './useCaptureErrorShownToUserEvent'

interface RqNotyf extends Notyf {
  /**
   * Use only if you **_really_** want to show an error without
   * logging to PostHog/Sentry. Otherwise, simply use `error()`
   */
  errorWithoutReporting: (
    payload: string | Partial<INotyfNotificationOptions>,
  ) => NotyfNotification
}

let notyf: RqNotyf | null = null

function useNotyf() {
  const captureErrorShownToUserEvent = useCaptureErrorShownToUserEvent()

  // Create a monkey-patched Notyf instance so that whenever the notyf.error()
  // method is called, we capture it as an event in Posthog & Sentry and can
  // be notified.
  function createNotyfInstance() {
    if (!notyf) {
      const NotyfOptions: Partial<INotyfOptions> = {
        duration: TOAST_AUTOCLOSE_DELAY,
        position: {
          x: 'right',
          y: 'top',
        },
        types: [
          {
            type: 'default',
            background: '#3B7DDD',
            icon: { className: 'notyf__icon--success', tagName: 'i' },
          },
          {
            type: 'info',
            background: '#3B7DDD',
            icon: renderToStaticMarkup(<FontAwesomeIcon icon={faCircleInfo} />),
          },
          {
            type: 'success',
            background: '#28a745',
            icon: { className: 'notyf__icon--success', tagName: 'i' },
          },
          {
            type: 'warning',
            background: '#ffc107',
            icon: { className: 'notyf__icon--error', tagName: 'i' },
          },
          {
            type: 'danger',
            background: '#dc3545',
            icon: { className: 'notyf__icon--error', tagName: 'i' },
          },
        ],
      }

      notyf = new Notyf(NotyfOptions) as RqNotyf

      const originalNotyfErrorHandler = notyf.error

      const monkeyPatchedErrorHandler: Notyf['error'] = (...args) => {
        captureErrorShownToUserEvent()
        return originalNotyfErrorHandler.apply(notyf, args)
      }

      notyf.error = monkeyPatchedErrorHandler
      notyf.errorWithoutReporting = originalNotyfErrorHandler
    }

    return notyf
  }

  const context = React.createContext(createNotyfInstance())

  return useContext(context)
}

export default useNotyf
