import truncate from 'lodash/truncate'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { User } from 'react-feather'
import { Link } from 'react-router'

import { useAuth } from '../../../../../hooks/useAuth'
import { useCurrentUserOrFail } from '../../../../../hooks/useCurrentUserOrFail'
import { isDevelopment } from '../../../../../lib/helpers/envHelpers'
import authService from '../../../../../lib/services/authService'
import MailTo from '../../../../misc/PanelInviteEmailTemplate/MailTo'

const IcaewAccountDropdown: React.FC = () => {
  const currentUser = useCurrentUserOrFail()
  const { signOut } = useAuth()

  return (
    <div className="d-sm-inline-block">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link" role="button">
          {currentUser?.avatar && (
            <img
              src={currentUser.avatar}
              className="avatar img-fluid rounded-circle me-1"
              referrerPolicy="no-referrer"
              alt={currentUser.fullName}
            />
          )}

          <span className="text-dark">
            <span
              title={isDevelopment() ? `User ID: ${currentUser.id}` : undefined}
            >
              {truncate(currentUser.fullName, { length: 20 })}{' '}
            </span>
          </span>
        </Dropdown.Toggle>
      </span>

      <Dropdown.Menu
        align="end"
        style={{ maxHeight: '740px', overflowY: 'auto' }}
      >
        <Dropdown.Item
          as={Link}
          to={authService.getAccountManagementUrl()}
          target="_blank"
        >
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>

        <Dropdown.Divider />

        <MailTo
          email="support@rq.app"
          subject={'Support request from ' + currentUser.fullName}
          body=""
          className="dropdown-item"
        >
          Contact Us
        </MailTo>

        <Dropdown.Item onClick={() => signOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </div>
  )
}

export default IcaewAccountDropdown
