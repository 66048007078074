import React, { useState } from 'react'

import { CompanyListInfo } from '../types/dtos/companies'

type UploadStatus = 'idle' | 'uploading' | 'success' | 'error'
type ImportReferralsError = {
  message: string
  fileName: string
}

interface ImportReferralContextType {
  company: CompanyListInfo | null
  file: File | null
  uploadStatus: UploadStatus
  uploadError: ImportReferralsError | null
  setCompany: (company: CompanyListInfo | null) => void
  setFile: (file: File | null) => void
  setUploadStatus: (status: UploadStatus) => void
  setUploadError: (error: ImportReferralsError | null) => void
}

const ImportReferralsContext = React.createContext<ImportReferralContextType>(
  {} as ImportReferralContextType,
)

interface Props {
  children: React.ReactNode
}

const ImportReferralsProvider: React.FC<Props> = ({ children }) => {
  const [company, setCompany] = useState<CompanyListInfo | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('idle')
  const [uploadError, setUploadError] = useState<ImportReferralsError | null>(
    null,
  )

  return (
    <ImportReferralsContext.Provider
      value={{
        company,
        file,
        uploadStatus,
        uploadError,
        setCompany,
        setFile,
        setUploadStatus,
        setUploadError,
      }}
    >
      {children}
    </ImportReferralsContext.Provider>
  )
}

export { ImportReferralsContext, ImportReferralsProvider }

export type { ImportReferralsError }
