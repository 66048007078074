import { useLocation } from 'react-router'

import { matchesPaths } from '../lib/helpers/routeHelpers'

export function useIsRoute() {
  const { pathname } = useLocation()

  return function (route: string) {
    return matchesPaths(pathname, [route])
  }
}
