import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockTwo, faSuitcase } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import {
  setOpen,
  setStep,
} from '../../../redux/slices/referralsImportHistorical'

const ImportHistoricalReferralsTypeChoice: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentCompany = useCurrentCompanyOrFail()
  const currentCompanyHasPanel = currentCompany.hasPanel ?? false

  function goToStep(stepName: 'upload-1' | 'upload-2') {
    // Make sure the modal's showing (sometimes it's ont yet)
    dispatch(setOpen(true))

    // Actually go to the step
    dispatch(setStep(stepName))
  }

  return (
    <div className="d-flex flex-sm-row flex-column gap-3">
      <button
        onClick={() => goToStep('upload-1')}
        type="button"
        disabled={!currentCompanyHasPanel}
        className="invite-type-button px-4 py-5 rounded-3 col d-flex flex-column align-items-center gap-2"
      >
        <FontAwesomeIcon icon={faClockTwo} size="3x" />
        Import historical referrals to firms in your relationships
      </button>

      <button
        onClick={() => goToStep('upload-2')}
        type="button"
        className="invite-type-button px-4 py-5 rounded-3 col d-flex flex-column align-items-center gap-2"
      >
        <FontAwesomeIcon icon={faSuitcase} size="3x" />
        Import historical referrals to (or from) firms not yet on RQ
      </button>
    </div>
  )
}

export default ImportHistoricalReferralsTypeChoice
