import { LOCAL_STORAGE_KEYS } from '../constants'

// A helper class to help us track if a page was reloaded programmatically
class PageReloadService {
  trackProgrammaticReload() {
    if (this.isProgrammaticReload()) {
      // Log message to help with debugging via Sentry & Posthog
      console.log('Page reloaded programmatically')
    }

    setTimeout(() => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.isProgrammaticReload)
    }, 3000)
  }

  isProgrammaticReload(): boolean {
    return (
      localStorage.getItem(LOCAL_STORAGE_KEYS.isProgrammaticReload) === 'true'
    )
  }

  reloadPage() {
    localStorage.setItem(LOCAL_STORAGE_KEYS.isProgrammaticReload, 'true')
    window.location.reload()
  }
}

const pageReloadService = new PageReloadService()

export default pageReloadService
