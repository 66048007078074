import { AxiosError } from 'axios'

import { ConstraintViolation } from '../../types/api'
import { FormErrors } from '../../types/misc'
import { API_VIOLATION_CODE } from '../constants'
import { isConstraintViolationListError } from '../helpers/helperFunctions'
import errorTypeService from './errorTypeService'

class ConstraintViolationService {
  getViolationByCode(
    error: unknown,
    code: string,
  ): ConstraintViolation | undefined {
    if (!isConstraintViolationListError(error)) {
      return
    }

    return error.response?.data?.violations.find(
      (violation) => violation.code === code,
    )
  }

  getViolationByPropertyPath(
    error: unknown,
    propertyPath: string,
  ): ConstraintViolation | undefined {
    if (!isConstraintViolationListError(error)) {
      return
    }

    return error.response?.data?.violations.find(
      (violation) => violation.propertyPath === propertyPath,
    )
  }

  formatErrors(error: unknown): FormErrors {
    const isAxiosError = error instanceof AxiosError
    if (!isAxiosError) {
      throw new Error('Not a ConstraintViolationListError')
    }

    const violations = error?.response?.data?.violations
    if (!Array.isArray(violations)) {
      throw new Error('Not a ConstraintViolationListError')
    }

    const formattedErrors: FormErrors = {}

    violations.forEach((violation: ConstraintViolation) => {
      formattedErrors[violation.propertyPath] = this.formatError(violation)
    })

    return formattedErrors
  }

  formatError(error: ConstraintViolation): string {
    if (
      /This collection should contain 1 element or more/i.test(error.message)
    ) {
      return 'Please select at least one option'
    }

    if (error.code === API_VIOLATION_CODE.invalidEnumValue) {
      return 'Please select a valid option'
    }

    return error.message
  }

  hasViolationWithCode(error: unknown, violationCode: string): boolean {
    if (!errorTypeService.isConstraintViolationListError(error)) {
      return false
    }

    const violations = error.response?.data.violations || []

    return violations.some((violation) => violation.code === violationCode)
  }
}

const constraintViolationService = new ConstraintViolationService()

export default constraintViolationService
