import ReactSelect, { GroupBase, Props } from 'react-select'

import { DEFAULT_REACT_SELECT_PROPS } from '../../lib/constants'

/**
 * Renders a `react-select` `Select` component, with sensible default props,
 * which will be overridden if passed.
 *
 * **Use exactly as you would `react-select`**.
 *
 * @see {ReactSelect}
 */
const Select: ReactSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group>,
) => {
  const finalProps: Props<Option, IsMulti, Group> = {
    ...(DEFAULT_REACT_SELECT_PROPS as Props<Option, IsMulti, Group>),
    ...props,
  }

  return <ReactSelect {...finalProps} />
}

export default Select
