import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@rq-ratings/pro-solid-svg-icons'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'

import {
  useCurrentCompanyServicesQuery,
  useInvalidateCurrentCompanyServicesQuery,
} from '../../../../../../hooks/useCompanyServicesQuery'
import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import { CompanyItem } from '../../../../../../types/responses/companies'
import CompanyFcaServiceDetailsRow, {
  ServiceFormValues,
} from './CompanyFcaServiceDetailsRow'
import OtherServicesField from './OtherServicesField'

interface Props {
  company: CompanyItem
  isIfaFirm: boolean
}

const CompanyFcaServices: React.FC<Props> = ({ company, isIfaFirm }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const [newService, setNewService] = useState<ServiceFormValues | null>(null)
  const invalidateCompanyServicesQuery =
    useInvalidateCurrentCompanyServicesQuery()

  const { isLoading: companyServicesQueryIsLoading, data: companyServices } =
    useCurrentCompanyServicesQuery()

  if (companyServicesQueryIsLoading || companyServices === undefined) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <Spinner />
      </div>
    )
  }

  function hasServices() {
    return companyServices !== undefined && companyServices.length > 0
  }

  return (
    <section className="pop-in">
      <div className="d-flex flex-column gap-3 align-items-stretch">
        {companyServices.map((service, index) => (
          <CompanyFcaServiceDetailsRow
            key={`${index}-${service.id}`}
            isEditingByDefault={false}
            rowNumber={index}
            service={{
              id: service.id,
              company: currentCompany['@id'],
              serviceArea: service.serviceArea,
              isOnlyInConjunctionWithFP: !service.isStandaloneService,
              notes: service.notes ?? '',
              serviceType: service.serviceType,
            }}
            onSave={() => {
              invalidateCompanyServicesQuery()
            }}
            onDelete={() => {
              invalidateCompanyServicesQuery()
            }}
          />
        ))}

        {newService && (
          <CompanyFcaServiceDetailsRow
            key="new"
            isEditingByDefault={true}
            rowNumber={-1}
            service={newService}
            // We're done with the new one now - it's in the collection
            onSave={async () => {
              await invalidateCompanyServicesQuery()

              setNewService(null)
            }}
            onDelete={() => setNewService(null)}
          />
        )}
      </div>

      {!newService && (
        <div
          className={classNames(
            'd-flex justify-content-center',
            hasServices() ? 'mt-3' : 'mb-5',
          )}
        >
          <Button
            onClick={() =>
              setNewService({
                id: undefined,
                company: currentCompany['@id'],
                serviceArea: undefined,
                isOnlyInConjunctionWithFP: false,
                notes: '',
                serviceType: undefined,
              })
            }
            variant={hasServices() ? 'link' : 'success'}
            size="lg"
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            {hasServices() ? 'Add another service' : 'Add a service'}
          </Button>
        </div>
      )}

      <OtherServicesField company={company} isIfaFirm={isIfaFirm} />
    </section>
  )
}

export default CompanyFcaServices
