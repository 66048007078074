import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-solid-svg-icons'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Badge } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../../../../../../hooks/useCurrentCompanyOrFail'
import { QUERY_KEYS } from '../../../../../../../lib/queryKeys'
import ActionButton from '../../../../../../misc/ActionButton'
import { useUpdateOtherServiceMutation } from '../../hooks/useUpdateOtherServiceMutation'

interface Props {
  suggestedServiceName: string
  /**
   * The text currently saved to the company record
   */
  currentOtherServicesString: string
}

const SuggestedServicePill: React.FC<Props> = ({
  suggestedServiceName,
  currentOtherServicesString,
}) => {
  const queryClient = useQueryClient()
  const currentCompany = useCurrentCompanyOrFail()

  const updateOtherSerciceMutation = useUpdateOtherServiceMutation({
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.company(currentCompany.id),
      }),
  })

  return (
    <Badge
      key={suggestedServiceName}
      className="d-flex align-items-center pop-in text-bg-light rounded-pill ps-3 pe-0"
      bg="light"
    >
      {suggestedServiceName}

      <ActionButton
        isProcessing={updateOtherSerciceMutation.isPending}
        isProcessingText=""
        variant="link"
        className="align-self-stretch py-0 px-2 text-secondary"
        onClick={() => {
          const newOtherServicesText = currentOtherServicesString
            .split(',')
            .filter(
              (part) =>
                part.trim().toLowerCase() !==
                suggestedServiceName.toLowerCase(),
            )
            .join(',')

          updateOtherSerciceMutation.mutate(newOtherServicesText)
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ActionButton>
    </Badge>
  )
}

export default SuggestedServicePill
