import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { reducers, ReferralsImportState } from './referralsImportBase'

const INITIAL_STATE: ReferralsImportState = {
  open: false,
  step: 'choose',
  initialStep: 'choose',
  onClientDuplicate: 'keep-original',
  currentImportItem: 0,
  importItems: [],
}

const referralsImportHistorical = createSlice({
  name: 'referralsImportHistorical',
  initialState: INITIAL_STATE,
  reducers,
})

export const referralsImportHistoricalReducer =
  referralsImportHistorical.reducer

export const selectReferralsImportHistorical = (state: RootState) =>
  state.referralsImportHistorical

export const {
  setOpen,
  setImportItems,
  updateImportItem,
  removeImportItem,
  setStep,
  setOnClientDuplicate,
  updateImportItemStatus,
  bulkUpdateAdvisor,
  bulkUpdateServiceArea,
} = referralsImportHistorical.actions
