import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode
  padded?: boolean
}

const PageContent: React.FC<Props> = ({
  children,
  className,
  padded = true,
  ...props
}) => (
  <div
    className={classNames(
      'page-content',
      className,
      padded ? 'page-content--padded' : 'page-content--no-padding',
    )}
    {...props}
  >
    {children}
  </div>
)

export default PageContent
