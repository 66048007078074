import { parseISO } from 'date-fns/parseISO'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

import { formatDate } from '../../../lib/helpers/helperFunctions'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
}

const ReferralDateCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
}) => {
  const [isEdit, setEdit] = useState(false)
  const isSet = !!importItem.referralDate
  const handleChange = (newDate: Date | null) => {
    const newImportItem = cloneDeep(importItem)
    newImportItem.referralDate = newDate ? newDate.toISOString() : null
    updateImportItem(newImportItem)
    setEdit(false)
  }

  return (
    <>
      {!isEdit && isSet ? (
        <>
          <span className="cursor-pointer" onClick={() => setEdit(true)}>
            {importItem.referralDate ? formatDate(importItem.referralDate) : ''}
          </span>
          <span className="show-on-row-hover text-xs">
            {', '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setEdit(true)}
            >
              change
            </Button>
          </span>
        </>
      ) : (
        <DatePicker
          selected={
            importItem.referralDate
              ? parseISO(importItem.referralDate)
              : undefined
          }
          onChange={handleChange}
          placeholderText="Select date"
          className="mw-100px form-control"
        />
      )}
    </>
  )
}

export default ReferralDateCell
