import * as React from 'react'
import { Navigate } from 'react-router'

import useAppSelector from '../../hooks/useAppSelector'
import { useAuth } from '../../hooks/useAuth'
import { useHomeRoute } from '../../hooks/useHomeRoute'
import { COMPANY_STATUS } from '../../lib/constants'
import { tokenFromSnapshotUrl } from '../../lib/helpers/loginHelpers'
import { buildSignInPageUrl } from '../../lib/helpers/routeHelpers'
import { ROUTES } from '../../lib/routes'
import { selectCurrentUserType } from '../../redux/slices/session'
import { UserType } from '../../types/misc'
import LoadingScreen from '../misc/LoadingScreen'

interface Props {
  children: React.ReactNode
  requiredUserType?: UserType
}

// Use to prevent unauthenticated users from accessing a route
const AuthGuard: React.FC<Props> = ({ children, requiredUserType }) => {
  const { currentUser, currentCompany, isAuthenticated, isInitialized } =
    useAuth()
  const homeRoute = useHomeRoute()
  const currentUserType = useAppSelector(selectCurrentUserType)

  if (!isInitialized) {
    return <LoadingScreen />
  }

  const isGuest = !isAuthenticated
  if (isGuest) {
    const token = tokenFromSnapshotUrl(window.location.pathname)
    const currentUri = window.location.href

    return (
      <Navigate
        to={buildSignInPageUrl({ token: token, redirectUri: currentUri })}
        replace
      />
    )
  }

  // Doesn't have the required userType
  if (requiredUserType && currentUserType !== requiredUserType) {
    return <Navigate to={homeRoute} />
  }

  const isNewUser = currentUser && !currentUserType
  if (isNewUser && window.location.pathname !== ROUTES.register) {
    return <Navigate to={ROUTES.register} />
  }

  const isSuspended =
    isAuthenticated && currentCompany?.status === COMPANY_STATUS.SUSPENDED
  if (isSuspended && window.location.pathname !== ROUTES.companySuspended) {
    return <Navigate to={ROUTES.companySuspended} />
  }

  return <>{children}</>
}

export default AuthGuard
