import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'

import CompleteRegistrationFlow from '../components/flows/CompleteRegistrationFlow/CompleteRegistrationFlow'
import { useHomeRoute } from '../hooks/useHomeRoute'

const CompleteRegistrationPage: React.FC = () => {
  const homeRoute = useHomeRoute()
  const navigate = useNavigate()

  return (
    <>
      <Helmet title="Complete registration" />

      <div
        style={{
          backgroundImage: 'linear-gradient(145deg, #2a3141, #1a6aac',
          backgroundAttachment: 'fixed',
          backgroundColor: '#cfeaff00',
        }}
      >
        <CompleteRegistrationFlow onComplete={() => navigate(homeRoute)} />
      </div>
    </>
  )
}

export default CompleteRegistrationPage
