import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'

import InviteReferralPartners from '../../../../misc/InviteReferralPartners/InviteReferralPartners'

const InviteReferralNetworkStep: React.FC = () => {
  return (
    <div className="pop-in">
      <p>
        To invite your professional connections to join you on RQ, add their
        email below and click send.
      </p>

      <p
        className="bg-primary-subtle p-2 rounded"
        style={{ color: 'var(--primary-dark)' }}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
        It's free for them to collaborate with you on RQ, and you can invite as
        many partners as you like.
      </p>

      <InviteReferralPartners className="text-start" />
    </div>
  )
}

export default InviteReferralNetworkStep
