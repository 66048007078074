import { useMutation } from '@tanstack/react-query'

import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import companyService from '../../../../../../lib/services/companyService'
import sentryService from '../../../../../../lib/services/sentryService'

interface Options {
  onSuccess?: () => void
}

export function useUpdateOtherServiceMutation({ onSuccess }: Options = {}) {
  const currentCompany = useCurrentCompanyOrFail()

  return useMutation({
    mutationFn: async (otherServicesString: string) =>
      companyService.updateOtherServices(
        currentCompany.id,
        otherServicesString,
      ),
    onError: () => {
      sentryService.captureMessage({
        message: 'Failed to update other services',
      })
    },
    onSuccess,
  })
}
