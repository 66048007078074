import { PayloadAction } from '@reduxjs/toolkit'

import { HydraMember } from '../../types/api'
import { FormErrors } from '../../types/misc'
import { BulkImportReferralCollectionItem } from '../../types/responses/referral-import'

export type ReferralsImportStep =
  | 'choose'
  | 'upload-1'
  | 'upload-2'
  | 'resolve-import'
export type ReferralsImportOnClientDuplicate = 'keep-original' | 'update'

export type ReferralsImportItem =
  HydraMember<BulkImportReferralCollectionItem> & {
    refUUID: string
    onClientDuplicate: ReferralsImportOnClientDuplicate
    importStatus: 'ready' | 'processing' | 'imported' | 'error'
    importErrors?: FormErrors
    bulkAllCount: number
    bulkCompanyCount: number
  }

export interface ReferralsImportState {
  open: boolean
  step: ReferralsImportStep
  initialStep: ReferralsImportStep
  onClientDuplicate: ReferralsImportOnClientDuplicate
  currentImportItem: number
  importItems: ReferralsImportItem[]
}

// Reducers
export const setOpenReducer = (
  state: ReferralsImportState,
  action: PayloadAction<boolean>,
) => {
  state.open = action.payload
}

export const setStepReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportState['step']>,
) => {
  state.step = action.payload
}

export const setOnClientDuplicateReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportState['onClientDuplicate']>,
) => {
  state.onClientDuplicate = action.payload
}

export const setImportItemsReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportState['importItems']>,
) => {
  state.importItems = action.payload
  updateBulkCount(state.importItems)
}

export const updateImportItemReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportItem>,
) => {
  const item = state.importItems.find(
    (i) => i.refUUID === action.payload.refUUID,
  )
  if (item) {
    Object.assign(item, action.payload)
  }
  updateBulkCount(state.importItems)
}

export const removeImportItemReducer = (
  state: ReferralsImportState,
  action: PayloadAction<string>,
) => {
  state.importItems = state.importItems.filter(
    (i) => i.refUUID !== action.payload,
  )
  if (state.importItems.length === 0) {
    state.open = false
    state.step = state.initialStep
  }
  updateBulkCount(state.importItems)
}

export const updateImportItemStatusReducer = (
  state: ReferralsImportState,
  action: PayloadAction<
    Pick<ReferralsImportItem, 'refUUID' | 'importStatus' | 'importErrors'>
  >,
) => {
  const item = state.importItems.find(
    (i) => i.refUUID === action.payload.refUUID,
  )
  if (item) {
    item.importStatus = action.payload.importStatus
    item.importErrors = action.payload.importErrors
  }
  updateBulkCount(state.importItems)
}

export const bulkUpdateAdvisorReducer = (
  state: ReferralsImportState,
  action: PayloadAction<
    Pick<ReferralsImportItem, 'toCompany' | 'toCompanyUser'>
  >,
) => {
  state.importItems.forEach((item) => {
    if (
      item.toCompany.id === action.payload.toCompany.id &&
      (item.importStatus === 'ready' || item.importStatus === 'error')
    ) {
      item.toCompanyUser = action.payload.toCompanyUser
      item.toCompanyUserDefaultUsed = false
    }
  })
}

export const bulkUpdateServiceAreaReducer = (
  state: ReferralsImportState,
  action: PayloadAction<Pick<ReferralsImportItem, 'toCompany' | 'serviceArea'>>,
) => {
  state.importItems.forEach((item) => {
    if (
      item.toCompany.id === action.payload.toCompany.id &&
      (item.importStatus === 'ready' || item.importStatus === 'error')
    ) {
      item.serviceArea = action.payload.serviceArea
      item.serviceAreaDefaultUsed = false
    }
  })
}

function updateBulkCount(items: ReferralsImportItem[]): void {
  const companiesMap = new Map<number, number>()
  let bulkAllCount = 0
  items.forEach((item) => {
    if (item.importStatus === 'ready' || item.importStatus === 'error') {
      companiesMap.set(
        item.toCompany.id,
        (companiesMap.get(item.toCompany.id) || 0) + 1,
      )
      bulkAllCount++
      item.bulkCompanyCount = 1
    } else {
      item.bulkCompanyCount = 0
    }
  })
  items.forEach((item) => {
    if (item.importStatus === 'ready' || item.importStatus === 'error') {
      item.bulkCompanyCount = companiesMap.get(item.toCompany.id) || 0
      item.bulkAllCount = bulkAllCount
    } else {
      item.bulkCompanyCount = 0
      item.bulkAllCount = 0
    }
  })
}

export const reducers = {
  setOpen: setOpenReducer,
  setStep: setStepReducer,
  setOnClientDuplicate: setOnClientDuplicateReducer,
  setImportItems: setImportItemsReducer,
  updateImportItem: updateImportItemReducer,
  removeImportItem: removeImportItemReducer,
  updateImportItemStatus: updateImportItemStatusReducer,
  bulkUpdateAdvisor: bulkUpdateAdvisorReducer,
  bulkUpdateServiceArea: bulkUpdateServiceAreaReducer,
}
