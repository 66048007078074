import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router'

import { SEARCH_PARAMS } from '../lib/constants'

export function useRestoreScrollPosition() {
  const pathname = useLocation().pathname
  const [searchParams, setSearchParams] = useSearchParams()
  const scrollY = searchParams.get(SEARCH_PARAMS.scrollY)

  useEffect(() => {
    if (scrollY) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollY, 10))

        searchParams.delete(SEARCH_PARAMS.scrollY)
        setSearchParams(searchParams)
      }, 50)
    }
  }, [pathname, scrollY, searchParams, setSearchParams])
}
