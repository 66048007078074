import classNames from 'classnames'
import React from 'react'

import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import { ROUTES } from '../../../../lib/routes'
import ActionLink from '../../../misc/ActionLink'

interface Props {
  containerClassName?: string
  className: string
  children: React.ReactNode
}

/**
 * Only displays anything if registration hasn't already been completed
 */
const CompleteRegistrationLinkButton: React.FC<Props> = ({
  containerClassName,
  className,
  children,
}) => {
  const currentCompany = useCurrentCompanyOrFail()

  if (currentCompany.isRegistrationComplete) {
    return null
  }

  return (
    <div className={containerClassName}>
      <ActionLink
        to={ROUTES.completeRegistration}
        className={classNames(className, 'btn btn-outline-warning')}
      >
        {children}
      </ActionLink>
    </div>
  )
}

export default CompleteRegistrationLinkButton
