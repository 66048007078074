import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ClientDetailsFormValues } from '../../pages/referralLink/constants'
import { RootState } from '../store'

export type ReferralLinkStep = 'clientDetails' | 'eSignature' | 'bookMeeting'

export type ReferralLinkSteps = Array<keyof typeof REFERRAL_LINK_STEP>

export interface ReferralLinkState {
  currentStep: ReferralLinkStep
  clientDetails: ClientDetailsFormValues
  hasBookedMeeting: boolean
}

export const REFERRAL_LINK_STEP: Record<ReferralLinkStep, ReferralLinkStep> = {
  clientDetails: 'clientDetails',
  eSignature: 'eSignature',
  bookMeeting: 'bookMeeting',
}

export const REFERRAL_LINK_INITIAL_STATE: ReferralLinkState = {
  currentStep: REFERRAL_LINK_STEP.clientDetails,
  clientDetails: {
    email: '',
    telephone: '',
    fullName: '',
    services: [],
    circumstances: '',
  },
  hasBookedMeeting: false,
}

export const referralLink = createSlice({
  name: 'referralLink',
  initialState: REFERRAL_LINK_INITIAL_STATE,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<ReferralLinkStep>) => {
      state.currentStep = action.payload
    },

    setClientDetails: (
      state,
      action: PayloadAction<ReferralLinkState['clientDetails']>,
    ) => {
      state.clientDetails = action.payload
    },

    setHasBookedMeeting: (
      state,
      action: PayloadAction<ReferralLinkState['hasBookedMeeting']>,
    ) => {
      state.hasBookedMeeting = action.payload
    },
  },
})

export const { setCurrentStep, setClientDetails, setHasBookedMeeting } =
  referralLink.actions

export const selectReferralLinkSlice = (state: RootState) => state.referralLink

export const selectCurrentStep = createSelector(
  selectReferralLinkSlice,
  (state) => state.currentStep,
)

export const selectClientDetails = createSelector(
  selectReferralLinkSlice,
  (state) => state.clientDetails,
)

export const selectHasBookedMeeting = createSelector(
  selectReferralLinkSlice,
  (state) => state.hasBookedMeeting,
)

export const referralLinkReducer = referralLink.reducer
