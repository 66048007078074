import { useQuery } from '@tanstack/react-query'
import { FieldProps } from 'formik'
import React from 'react'

import { QUERY_KEYS } from '../../../../../../../lib/queryKeys'
import fcaServiceTypeService from '../../../../../../../lib/services/fcaServiceTypeService'
import Select from '../../../../../../select/Select'

interface Props extends FieldProps {
  className: string
}

const SelectFcaServiceTypeField: React.FC<Props> = ({
  field,
  form,
  className,
}) => {
  const fcaServiceTypesQuery = useQuery({
    queryKey: QUERY_KEYS.fcaServiceTypes,
    queryFn: () => fcaServiceTypeService.getAll(),
  })

  const options =
    fcaServiceTypesQuery.isLoading || fcaServiceTypesQuery.data === undefined
      ? []
      : fcaServiceTypesQuery.data.map((fcaServiceType) => ({
          value: fcaServiceType,
          label: fcaServiceType.name,
        }))

  return (
    <Select
      className={className}
      options={options}
      name={field.name}
      value={options.find((option) => option.value.id === field?.value?.id)}
      onChange={(option) => form.setFieldValue(field.name, option?.value)}
      onBlur={field.onBlur}
      // important because this opens inside an overflow: hidden
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  )
}

export default SelectFcaServiceTypeField
