import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-regular-svg-icons'
import { faTrianglePersonDigging } from '@rq-ratings/pro-solid-svg-icons'
import React, { MouseEventHandler, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router'

import rqLogoUrl from '../../../../../assets/img/logo.svg'
import compassLogoUrl from '../../../../../assets/img/logo-compass.svg'
import icaewLogoUrl from '../../../../../assets/img/logo-icaew.png'
import useAppSelector from '../../../../../hooks/useAppSelector'
import { useAuth } from '../../../../../hooks/useAuth'
import { useHomeRoute } from '../../../../../hooks/useHomeRoute'
import useSidebar from '../../../../../hooks/useSidebar'
import { isProduction } from '../../../../../lib/helpers/envHelpers'
import { selectCompanySponsor } from '../../../../../redux/slices/session'
import ClientSponsorCompany from './ClientSponsorCompany'
import SidebarFooter from './SidebarFooter'
import SidebarNav from './SidebarNav'
import SidebarSponsoredBy from './SidebarSponsoredBy'

export interface SidebarPage {
  href: string
  title: string
  icon: React.ReactElement
  children?: SidebarPage[]
  badge?: string
  onClick?: MouseEventHandler
  className?: string
  linkClassName?: string
  iconClassName?: string
}

interface Props {
  items: { title?: string; pages: SidebarPage[] }[]
  open?: boolean
  shouldShowFooter?: boolean
}

const Sidebar: React.FC<Props> = ({ items, shouldShowFooter = false }) => {
  const homeRoute = useHomeRoute()
  const [showDevLogo, setShowDevLogo] = useState(() => {
    return !isProduction()
  })

  // @todo don't call it "isOpen" - on mobile that's the opposite of what happens
  const { isOpen, setIsOpen } = useSidebar()
  const { isIcaewUser, isClientUser } = useAuth()
  const companySponsor = useAppSelector(selectCompanySponsor)

  function renderLogo() {
    if (isIcaewUser) {
      return (
        <img src={icaewLogoUrl} style={{ maxWidth: '110px' }} alt="RQ ICAEW" />
      )
    }

    if (isClientUser) {
      return (
        <img src={compassLogoUrl} style={{ maxWidth: '180px' }} alt="Compass" />
      )
    }

    return <img src={rqLogoUrl} style={{ maxWidth: '65px' }} alt="RQ" />
  }

  return (
    // @todo don't call it "collapsed" - on mobile that's the opposite of what happens
    <nav className={`sidebar ${isOpen ? '' : 'collapsed'}`}>
      <div className="sidebar-content">
        <PerfectScrollbar className="d-flex flex-column min-vh-100">
          <button
            className="sidebar-close"
            onClick={() => {
              setIsOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faTimes} className="fs-1" />
          </button>

          <Link
            className="sidebar-brand"
            to={homeRoute}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            {renderLogo()}
          </Link>

          {showDevLogo && (
            <FontAwesomeIcon
              icon={faTrianglePersonDigging}
              className="text-warning"
              style={{ position: 'absolute', top: '4px', left: '4px' }}
              size="2x"
              onClick={() => {
                setShowDevLogo(false)
                setInterval(function () {
                  setShowDevLogo(true)
                }, 120000)
              }}
            />
          )}

          <SidebarNav items={items} />

          <div className="d-flex flex-column align-items-center gap-5 pb-5">
            {companySponsor && <SidebarSponsoredBy sponsor={companySponsor} />}
          </div>

          {shouldShowFooter && <SidebarFooter />}

          {isClientUser && (
            <ClientSponsorCompany
              className="text-center px-4 pb-5"
              variant="plain"
            />
          )}
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default Sidebar
