import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { Alert } from 'react-bootstrap'

import { THEME_PALETTE_LIGHT } from '../../lib/constants'

interface Props {
  title?: string
  variant: Variant
  children: React.ReactNode
  className?: string
  style?: CSSProperties
  icon?: IconProp
  iconStyle?: CSSProperties
}

type Variant = 'blue' | 'yellowLight' | 'yellowDark' | 'success' | 'danger'

const InfoBox: React.FC<Props> = ({
  title,
  children,
  variant,
  className,
  style = {},
  icon,
  iconStyle,
}) => {
  // TODO: Probably better to use SASS variables instead
  const styles: CSSProperties = {
    backgroundColor: {
      blue: THEME_PALETTE_LIGHT.info,
      yellowLight: THEME_PALETTE_LIGHT.warningExtraLight,
      yellowDark: '#FFF0DD',
      success: THEME_PALETTE_LIGHT.successLight,
      danger: THEME_PALETTE_LIGHT.danger,
    }[variant],
    color: variant === 'danger' ? 'white' : 'inherit',
    ...style,
  }

  const iconColor = {
    blue: 'inherit',
    yellowLight: THEME_PALETTE_LIGHT.warning,
    yellowDark: THEME_PALETTE_LIGHT.warning,
    success: THEME_PALETTE_LIGHT.success,
    danger: THEME_PALETTE_LIGHT.white,
  }[variant]

  return (
    <Alert
      variant={variant}
      className={classNames('d-flex align-items-start py-3 px-4', className)}
      style={styles}
    >
      {icon && (
        <FontAwesomeIcon
          className="position-relative"
          icon={icon}
          style={{
            color: iconColor,
            fontSize: '19px',
            marginRight: '10px',
            ...iconStyle,
          }}
        />
      )}

      <div>
        {title && <h4 className="mb-2">{title}</h4>}
        {children}
      </div>
    </Alert>
  )
}

export default InfoBox
