import { useMutation } from '@tanstack/react-query'

import usePosthogCapture from '../../../hooks/posthogCapture/usePosthogCapture'
import { useInvalidatePanelQueries } from '../../../hooks/useInvalidatePanelQueries'
import useNotyf from '../../../hooks/useNotyf'
import { useRecommendedPartnerModal } from '../../../hooks/useRecommendedPartnerModal'
import recommendedPartnerService from '../../../lib/services/recommendedPartnerService'

interface MutationVariables {
  partnerId: number
  partnerName: string
}

export function useAcceptRecommendedPartnerMutation() {
  const notyf = useNotyf()
  const invalidatePanelQueries = useInvalidatePanelQueries()
  const recommendedPartnerModal = useRecommendedPartnerModal()
  const { posthogCapture } = usePosthogCapture()

  return useMutation({
    mutationFn: (variables: MutationVariables) =>
      recommendedPartnerService.acceptRecommendedPartner(variables.partnerId),
    onSuccess: async (_data, variables) => {
      await invalidatePanelQueries()
      recommendedPartnerModal.hide()
      notyf.success(
        `${variables.partnerName} has been added to your professional network`,
      )

      posthogCapture({
        appArea: 'relationship-management',
        action: 'vetted-relationship-added',
      })
    },
    onError: () => {
      notyf.error('There was an error adding the vetted partner.')
    },
  })
}
