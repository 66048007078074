import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

import { HydraMember } from '../../../../../../../types/api'
import { CompanyServiceItem } from '../../../../../../../types/responses/companies'
import SelectedServicePill from './SelectedServicePill'
import SuggestedServicePill from './SuggestedServicePill'

interface Props {
  searchTerm: string
  onChange: (newSearchTerm: string) => void
  selectedServices: HydraMember<CompanyServiceItem[]>
  otherServicesString: string
}

const ServicesSearchForm: React.FC<Props> = ({
  searchTerm,
  onChange,
  selectedServices,
  otherServicesString,
}) => {
  return (
    <div className="mb-3 align-self-stretch">
      <InputGroup size="lg">
        <InputGroup.Text className="border border-secondary">
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>

        <Form.Control
          type="search"
          className="border border-secondary"
          value={searchTerm}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Search all services"
          spellCheck
        />
      </InputGroup>

      <div className="align-self-stretch d-flex flex-wrap align-items-center justify-content-start gap-1 mt-2">
        <span>
          {selectedServices.length === 0 &&
          otherServicesString.trim().length === 0
            ? ' '
            : 'Your selected services:'}
        </span>

        {otherServicesString
          .trim()
          .split(',')
          // Because an empty string still returns one empty part
          .filter((part) => part !== '')
          .map((otherServiceName: string) => (
            <SuggestedServicePill
              key={otherServiceName}
              currentOtherServicesString={otherServicesString}
              suggestedServiceName={otherServiceName}
            />
          ))}

        {selectedServices.map((service: CompanyServiceItem) => (
          <SelectedServicePill key={service.id} service={service} />
        ))}
      </div>
    </div>
  )
}

export default ServicesSearchForm
