import { useMutation } from '@tanstack/react-query'

import useNotyf from '../../../../../../hooks/useNotyf'
import errorTypeService from '../../../../../../lib/services/errorTypeService'
import serviceService from '../../../../../../lib/services/serviceService'

interface Options {
  onSuccess?: () => void
  onError: () => void
  onMutate: () => void
}

export function useDeleteServiceMutation({
  onSuccess,
  onError,
  onMutate,
}: Options) {
  const notyf = useNotyf()

  return useMutation({
    mutationFn: async (serviceId: number) =>
      serviceService.deleteService(serviceId),
    onMutate: onMutate,
    onSuccess: onSuccess,
    onError: (error) => {
      // Sometimes it's forbidden because the service already has a referral attached
      if (
        errorTypeService.isApiError(error) &&
        errorTypeService.isForbiddenError(error)
      ) {
        notyf.error({
          message: `Cannot remove: ${error.response?.data['hydra:description']}`,
          duration: 4000,
        })
      } else {
        notyf.error('Something went wrong - please try again')
      }

      onError()
    },
  })
}
