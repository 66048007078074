import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Navigate } from 'react-router'

import useAppSelector from '../../../hooks/useAppSelector'
import { useHomeRoute } from '../../../hooks/useHomeRoute'
import { useRqCertifiedAssessment } from '../../../hooks/useRqCertifiedAssessmentQuery'
import { selectIsIfa } from '../../../redux/slices/session'
import PageContainer from '../../misc/PageContainer'
import RqCertifiedActionButtons from './components/RqCertifiedActionButtons/RqCertifiedActionButtons'
import RqCertifiedPageHeader from './components/RqCertifiedPageHeader'

interface Props {
  children: React.ReactNode
}

const RqCertifiedLayout: React.FC<Props> = ({ children }) => {
  const isIfa = useAppSelector(selectIsIfa)
  const homeRoute = useHomeRoute()
  const assessment = useRqCertifiedAssessment()

  if (!isIfa || !assessment) {
    return <Navigate to={homeRoute} />
  }

  return (
    <>
      <RqCertifiedPageHeader />

      <PageContainer>
        <Row>
          <Col xl={10}>
            <Card>
              <Card.Body className="p-4">
                {children}
                <RqCertifiedActionButtons />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </PageContainer>
    </>
  )
}

export default RqCertifiedLayout
