import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import invariant from 'tiny-invariant'
import { objectKeys } from 'ts-extras'

import usePosthogCapture from '../../../../hooks/posthogCapture/usePosthogCapture'
import useAppDispatch from '../../../../hooks/useAppDispatch'
import useAppSelector from '../../../../hooks/useAppSelector'
import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import { useInvalidateOnboardingChecklistQuery } from '../../../../hooks/useInvalidateOnboardingChecklistQuery'
import useMaxWidth from '../../../../hooks/useMaxWidth'
import useNotyf from '../../../../hooks/useNotyf'
import { POSTHOG_APP_AREA } from '../../../../lib/constants'
import companyService from '../../../../lib/services/companyService'
import { selectIsEditing } from '../../../../redux/slices/completeRegistrationPopup'
import { completeCompanyRegistration } from '../../../../redux/slices/session'
import ActionButton from '../../../misc/ActionButton'
import {
  CompleteRegistrationFlowStepDetails,
  CompleteRegistrationFlowStepKey,
} from '../CompleteRegistrationFlow'

interface Props {
  currentStepKey: CompleteRegistrationFlowStepKey
  flowSteps: Record<
    CompleteRegistrationFlowStepKey,
    CompleteRegistrationFlowStepDetails
  >
  visitedSteps: CompleteRegistrationFlowStepKey[]
  onComplete?: () => void
  onStepChange: (newStepKey: CompleteRegistrationFlowStepKey) => void
}

const CompleteRegistrationNavigation: React.FC<Props> = ({
  currentStepKey,
  flowSteps,
  visitedSteps,
  onComplete,
  onStepChange,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const notyf = useNotyf()
  const { posthogCapture } = usePosthogCapture()
  const dispatch = useAppDispatch()
  const isEditing = useAppSelector(selectIsEditing)
  const isMaxWidthMd = useMaxWidth('md')

  const completeRegistrationMutation = useMutation({
    mutationFn: () => companyService.completeRegistration(currentCompany.id),
    onSuccess: async () => {
      dispatch(completeCompanyRegistration())

      await invalidateOnboardingChecklistQuery()
      notyf.success('Registration successfully completed')

      posthogCapture({
        appArea: POSTHOG_APP_AREA.completeRegistrationModal,
        action: 'step-completed',
        step: 'uploadVideo',
      })

      posthogCapture({
        appArea: POSTHOG_APP_AREA.completeRegistrationModal,
        action: 'completed',
      })

      if (onComplete) {
        onComplete()
      }
    },
    onError: () => {
      notyf.error('There was a problem completing your registration')
    },
  })

  function goToStepAfter(step: CompleteRegistrationFlowStepKey): void {
    const flowStepKeys = objectKeys(flowSteps)
    const currentStepIndex = flowStepKeys.indexOf(step)

    const newStepKey =
      flowStepKeys[
        // Don't go past the last one
        Math.min(currentStepIndex + 1, flowStepKeys.length - 1)
      ]

    invariant(newStepKey, 'Expected newStepKey to be defined')

    const newStep = flowSteps[newStepKey]
    invariant(newStep, 'Expected newStep to be defined')

    if (!newStep.isNecessary) {
      // Skip this unnecessary step, and move forward again
      goToStepAfter(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  function goToStep(key: CompleteRegistrationFlowStepKey) {
    if (
      isEditing &&
      !confirm(
        'Changes will be lost if you move away from this step. Move away?',
      )
    ) {
      // Cancel the navigation
      return
    }

    onStepChange(key)
  }

  function isReadyToComplete(): boolean {
    for (const step of Object.values(flowSteps)) {
      if (step.isRequired && !step.isComplete()) {
        return false
      }
    }

    return true
  }

  const invalidateOnboardingChecklistQuery =
    useInvalidateOnboardingChecklistQuery()

  const currentStep = flowSteps[currentStepKey]

  invariant(currentStep, 'Expected currentStep to be defined')

  const isNavigationAllowed =
    !currentStep.isRequired || currentStep.isComplete()

  function handleCompleteStep() {
    posthogCapture({
      appArea: POSTHOG_APP_AREA.completeRegistrationModal,
      action: 'step-completed',
      step: currentStepKey,
    })

    goToStepAfter(currentStepKey)
  }

  function currentStepIsFirstStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[0]
  }

  function currentStepIsLastStep(): boolean {
    const flowStepKeys = Object.keys(flowSteps)

    return currentStepKey === flowStepKeys[flowStepKeys.length - 1]
  }

  function goToStepBefore(current: CompleteRegistrationFlowStepKey): void {
    const flowStepKeys = Object.keys(
      flowSteps,
    ) as CompleteRegistrationFlowStepKey[]
    const currentStepIndex = flowStepKeys.indexOf(current)

    const newStepKey = flowStepKeys[
      // Don't go past the first one
      Math.max(currentStepIndex - 1, 0)
    ] as CompleteRegistrationFlowStepKey

    if (!flowSteps[newStepKey]?.isNecessary) {
      // Skip this unnecessary step, and move back again
      goToStepBefore(newStepKey)

      return
    }

    goToStep(newStepKey)
  }

  return (
    <footer
      className={classNames(
        'sticky-bottom d-flex justify-content-center',
        isMaxWidthMd ? 'p-2 gap-2' : 'p-2 mb-2 bottom-2 gap-3 rounded-3',
      )}
      style={
        isMaxWidthMd
          ? { backgroundColor: '#1a6aac' }
          : { backgroundColor: '#ffffff00', backdropFilter: 'blur(16px)' }
      }
    >
      {/* Step actions */}
      {currentStepIsFirstStep() ? (
        <div className="flex-1"></div>
      ) : (
        <Button
          variant="light"
          size="lg"
          onClick={() => goToStepBefore(currentStepKey)}
          disabled={!isNavigationAllowed}
          className="flex-1"
        >
          Back
        </Button>
      )}

      {/* Step indicators */}
      {!isMaxWidthMd && (
        <div
          id="complete-registration-modal-steps-indicator"
          style={
            {
              '--number-of-columns': Object.values(flowSteps).filter(
                (step) => step.isNecessary,
              ).length,
            } as CSSProperties
          }
        >
          {objectKeys(flowSteps).map(
            (stepKey: CompleteRegistrationFlowStepKey) => {
              if (!flowSteps[stepKey]?.isNecessary) {
                return null
              }

              return (
                <OverlayTrigger
                  key={stepKey}
                  overlay={<Tooltip>{flowSteps[stepKey].name}</Tooltip>}
                >
                  {/* The clickable area, mostly invisible. Bigger than the
                      visible bit as otherwise the click/touch target is too small */}
                  <button
                    type="button"
                    onClick={() => {
                      if (isNavigationAllowed) {
                        goToStep(stepKey)
                      } else {
                        notyf.errorWithoutReporting(
                          'Please complete this step before moving to another',
                        )
                      }
                    }}
                  >
                    {/* The visual bit */}
                    <span
                      className={stepKey === currentStepKey ? 'bg-primary' : ''}
                    ></span>

                    {stepKey !== currentStepKey &&
                      visitedSteps.includes(stepKey) &&
                      !flowSteps[stepKey].isComplete() && (
                        <div
                          className="bg-warning"
                          style={{
                            position: 'absolute',
                            width: '.5rem',
                            height: '.5rem',
                            borderRadius: '.25rem',
                            left: '50%',
                            translate: '-50%',
                          }}
                        >
                          {' .'}
                        </div>
                      )}
                  </button>
                </OverlayTrigger>
              )
            },
          )}
        </div>
      )}

      {!currentStepIsLastStep() && (
        <Button
          variant="primary"
          size="lg"
          onClick={handleCompleteStep}
          disabled={!isNavigationAllowed}
          className="flex-1"
        >
          Next
        </Button>
      )}

      {currentStepIsLastStep() && (
        <ActionButton
          variant="success"
          size="lg"
          onClick={() => completeRegistrationMutation.mutate()}
          disabled={!isReadyToComplete()}
          isProcessing={completeRegistrationMutation.isPending}
          className="flex-1"
        >
          Finish
        </ActionButton>
      )}
    </footer>
  )
}

export default CompleteRegistrationNavigation
