import { useQueryClient } from '@tanstack/react-query'
import React from 'react'

import useCurrentCompanyOrFail from '../../../../../../../hooks/useCurrentCompanyOrFail'
import { QUERY_KEYS } from '../../../../../../../lib/queryKeys'
import ActionButton from '../../../../../../misc/ActionButton'
import { useUpdateOtherServiceMutation } from '../../hooks/useUpdateOtherServiceMutation'

interface Props {
  /**
   * The user's text, which should represent ONE suggested other service
   */
  inputText: string
  /**
   * The text currently saved to the company record
   */
  currentOtherServicesString: string
  onSave: () => void
}

const SuggestNewServiceArea: React.FC<Props> = ({
  inputText,
  currentOtherServicesString,
  onSave,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const queryClient = useQueryClient()
  const trimmedInputText = inputText.trim()
  // Don't allow commas, because this is going into a comma-separated list
  const cleanedInputText = trimmedInputText.replaceAll(',', '')

  const updateOtherServiceMutation = useUpdateOtherServiceMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.company(currentCompany.id),
      })

      onSave()
    },
  })

  const alreadyExists = currentOtherServicesString
    .split(',')
    .map((part) => part.trim().toLowerCase())
    .includes(trimmedInputText.toLowerCase())

  if (alreadyExists) {
    return null
  }

  return (
    <>
      <ActionButton
        isProcessing={updateOtherServiceMutation.isPending}
        variant="primary"
        onClick={() => {
          const newOtherServicesText = currentOtherServicesString
            ? `${currentOtherServicesString},${cleanedInputText}`
            : cleanedInputText

          updateOtherServiceMutation.mutate(newOtherServicesText)
        }}
      >
        Suggest adding "{cleanedInputText}"
      </ActionButton>

      <p className="small">
        If you click, RQ will be notified that you'd like to add this service.
      </p>
    </>
  )
}

export default SuggestNewServiceArea
