import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-solid-svg-icons'
import React, { useState } from 'react'
import { Badge } from 'react-bootstrap'

import { useInvalidateCurrentCompanyServicesQuery } from '../../../../../../../hooks/useCompanyServicesQuery'
import { CompanyServiceItem } from '../../../../../../../types/responses/companies'
import ActionButton from '../../../../../../misc/ActionButton'
import { useDeleteServiceMutation } from '../../hooks/useDeleteServiceMutation'

interface Props {
  service: CompanyServiceItem
}

const SelectedServicePill: React.FC<Props> = ({ service }) => {
  const invalidateCompanyServicesQuery =
    useInvalidateCurrentCompanyServicesQuery()

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteServiceMutation = useDeleteServiceMutation({
    onMutate: () => setIsDeleting(true),
    onError: () => setIsDeleting(false),
    onSuccess: () => {
      invalidateCompanyServicesQuery()
    },
  })

  return (
    <Badge
      key={service.id}
      className="d-flex align-items-center pop-in text-bg-light rounded-pill ps-3 pe-0"
      bg="light"
    >
      {service.serviceArea.name}

      <ActionButton
        isProcessing={isDeleting}
        isProcessingText=""
        variant="link"
        className="align-self-stretch py-0 px-2 text-secondary"
        onClick={() => deleteServiceMutation.mutate(service.id)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ActionButton>
    </Badge>
  )
}

export default SelectedServicePill
