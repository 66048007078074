import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { reducers, ReferralsImportState } from './referralsImportBase'

const INITIAL_STATE: ReferralsImportState = {
  open: false,
  step: 'upload-1',
  initialStep: 'upload-1',
  onClientDuplicate: 'keep-original',
  currentImportItem: 0,
  importItems: [],
}

const referralsImportNew = createSlice({
  name: 'referralsImportNew',
  initialState: INITIAL_STATE,
  reducers,
})

export const referralsImportNewReducer = referralsImportNew.reducer

export const selectReferralsImportNew = (state: RootState) =>
  state.referralsImportNew

export const {
  setOpen,
  setImportItems,
  updateImportItem,
  removeImportItem,
  setStep,
  setOnClientDuplicate,
  updateImportItemStatus,
  bulkUpdateAdvisor,
  bulkUpdateServiceArea,
} = referralsImportNew.actions
