import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react'
import React from 'react'

import EmbeddedErrorFallback from '../layouts/DashboardLayout/components/EmbeddedErrorFallback'
import FullPageErrorFallback from './FullPageErrorFallback'

type Props = ErrorBoundaryProps & {
  variant?: 'embedded' | 'full-screen'
  fallbackComponent?: React.ReactNode
}

const SentryErrorBoundary: React.FC<Props> = ({
  variant = 'embedded',
  fallbackComponent,
  ...rest
}) => {
  return (
    <ErrorBoundary
      fallback={(error) => {
        return (
          <div className="SentryErrorBoundary">
            {variant === 'embedded' ? (
              <EmbeddedErrorFallback
                error={error}
                fallbackComponent={fallbackComponent}
              />
            ) : (
              <FullPageErrorFallback
                error={error}
                fallbackComponent={fallbackComponent}
              />
            )}
          </div>
        )
      }}
      {...rest}
    />
  )
}

export default SentryErrorBoundary
