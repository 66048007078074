import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'
import invariant from 'tiny-invariant'

import { UserType } from '../../types/misc'
import {
  SessionMetadataItem,
  SessionMetadataUser,
} from '../../types/responses/session-metadata'
import { getApiUrl } from '../helpers/apiHelpers'
import {
  isDevelopment,
  isFeature,
  isProduction,
  isStaging,
} from '../helpers/envHelpers'
import { slugify } from '../helpers/helperFunctions'
import errorTypeService from './errorTypeService'

class SentryService {
  init() {
    const apiUrl = getApiUrl()
    invariant(apiUrl, 'Failed to determine API URL')

    Sentry.init({
      enabled: this.isEnabled(),
      normalizeDepth: 7,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: this.getEnvironment(),

      // Only attach Sentry headers to outgoing XHR requests to the API
      // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
      tracePropagationTargets: [apiUrl],

      ignoreErrors: [
        // This error surfaces in Safari and appears to come from Posthog.
        // We need to investigate further but as it doesn't seem to affect the
        // user experience, let's filter it out to avoid depleting our quota.
        "undefined is not an object (evaluating 'o[Symbol.iterator]')",
      ],

      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        posthog.sentryIntegration({
          organization: 'rq-00',
          projectId: 6549839,
        }),
      ],

      tracesSampleRate: 0.2,
    })
  }

  isEnabled() {
    return !isDevelopment()
  }

  getEnvironment() {
    switch (true) {
      case isDevelopment():
        return 'development'
      case isStaging():
        return 'staging'
      case isFeature():
        return 'feature'
      case isProduction():
        return 'production'
      default:
        return 'unknown'
    }
  }

  captureApiError(error: unknown) {
    Sentry.setExtra('error', error)

    const isAxiosError = errorTypeService.isAxiosError(error)

    if (isAxiosError) {
      Sentry.setExtra('error.request', error.request)
      Sentry.setExtra('error.response', error.response)
    }

    if (error instanceof Error) {
      // Sentry's captureException() method requires an instance of Error
      // otherwise it'll throw an error.
      Sentry.captureException(error)
    }

    const statusCode = isAxiosError ? error.response?.status : undefined
    const errorMessage = statusCode ? `API error: ${statusCode}` : 'API error'

    sentryService.captureMessage({
      messageId: 'api-error',
      message: errorMessage,
      extra: { error },
    })

    posthog.capture(errorMessage)
  }

  setAppContext(context: SentryAppContext) {
    const { currentUser, currentCompany, currentUserType, isOutlook } = context

    if (!currentUser || !currentCompany) {
      return
    }

    const appContext = {
      currentUser: {
        id: currentUser.id,
        displayName: currentUser.fullName,
        email: currentUser.email,
        isImpersonated: currentUser.impersonated,
      },
      currentCompany: {
        iri: currentCompany?.['@id'],
        name: currentCompany?.name,
      },
      currentUserType,
      isOutlook,
    }

    Sentry.setContext('App Context', appContext)
  }

  captureMessage(options: {
    message: string
    messageId?: string
    extra?: SentryExtraContext
  }) {
    const { message, messageId, extra } = options

    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtra('extra', extra)
      }

      Sentry.captureMessage(message, {
        tags: { 'app.messageId': messageId || slugify(message) },
        extra,
      })
    })
  }
}

export type SentryExtraContext = Record<string, unknown>

export interface SentryAppContext {
  currentUser: SessionMetadataUser
  currentCompany?: SessionMetadataItem['currentCompany']
  currentUserType: UserType
  isOutlook: boolean
}

const sentryService = new SentryService()

export default sentryService
