import classNames from 'classnames'
import React from 'react'
import { Card } from 'react-bootstrap'

import { useClientMetadata } from '../../../../../hooks/useClientMetadata'
import { useCompassMetadataOrFail } from '../../../../flows/CompassFlow/utils/useCompassMetadataOrFail'
import CompanyLogoOrName from '../../../../misc/CompanyLogoOrName'
import RequestCompanyCallbackButton from '../../../../misc/RequestCompanyCallbackButton/RequestCompanyCallbackButton'

interface Props {
  className?: string
  variant?: 'card' | 'plain'
}

const ClientSponsorCompany: React.FC<Props> = ({ className, variant }) => {
  const clientMetadata = useClientMetadata()
  const compassMetadata = useCompassMetadataOrFail()

  if (!clientMetadata && !compassMetadata) {
    return null
  }

  const sponsorCompany =
    clientMetadata?.sponsorCompany ?? compassMetadata.company

  if (!sponsorCompany) {
    return null
  }

  const isCardVariant = variant === 'card'
  const isPlainVariant = variant === 'plain'

  const render = () => {
    return (
      <div className={classNames('d-inline-block', className)}>
        {isPlainVariant && <div className="fw-bold">Introduced by:</div>}

        <CompanyLogoOrName
          name={sponsorCompany.presentationName}
          logoUrl={sponsorCompany.logoUrl}
          showNameFallback={true}
          showLogoFallback={false}
          imageStyle={{
            maxWidth: '190px',
            maxHeight: '60px',
          }}
          fallbackProps={{
            className: 'lead text-base justify-content-center mt-1',
          }}
        />

        <div style={{ marginTop: '15px' }}>
          <RequestCompanyCallbackButton
            compassPartner={sponsorCompany}
            isCreateReferral={false}
          />
        </div>
      </div>
    )
  }

  if (isCardVariant) {
    return (
      <Card className="box-shadow-lg border-box d-inline-block">
        <Card.Body style={{ padding: '16px 20px 24px' }}>{render()}</Card.Body>
      </Card>
    )
  }

  return render()
}

export default ClientSponsorCompany
