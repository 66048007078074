import { useMedia } from 'react-use'

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

// See https://getbootstrap.com/docs/5.2/layout/breakpoints/
function useMaxWidth(breakpoint: Breakpoint) {
  const breakpointDimensions: Record<Breakpoint, number> = {
    sm: 575,
    md: 767,
    lg: 991,
    xl: 1119,
    xxl: 1399,
  }

  return useMedia(`(max-width: ${breakpointDimensions[breakpoint]}px)`)
}

export default useMaxWidth
