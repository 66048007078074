import { useMutation, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { COMPANY_STATUS, LOCAL_STORAGE_KEYS } from '../lib/constants'
import { isOfficeAddIn } from '../lib/helpers/officeHelpers'
import { QUERY_KEYS } from '../lib/queryKeys'
import authService from '../lib/services/authService'
import posthogService from '../lib/services/posthogService'
import rqCertifiedAssessmentService from '../lib/services/rqCertifiedAssessmentService'
import sentryService from '../lib/services/sentryService'
import sessionService from '../lib/services/sessionService'
import {
  initialiseSessionError,
  initialiseSessionFail,
  initialiseSessionSuccess,
} from '../redux/slices/session'
import useAppDispatch from './useAppDispatch'

export function useCreateSessionMetadataMutation() {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const { isAuthenticated } = await authService.initializeKeycloak()

      // User is not logged in
      if (!isAuthenticated) {
        dispatch(initialiseSessionFail())
        return
      }

      // User is logged in, so let's fetch session data from the API
      const sessionMetadata = await sessionService.createSessionMetadata({
        previouslySelectedScope: authService.getPreviouslyUserSelectedScope(),
        previouslySelectedCompanyId:
          authService.getPreviouslyUserSelectedCompanyId(),
      })

      const { redirectUri } = sessionMetadata

      if (redirectUri && redirectUri !== window.location.href) {
        window.location.assign(redirectUri)
      }

      const { currentUser, currentCompany, currentUserType } = sessionMetadata

      invariant(currentUser, 'Expected user to be set')

      posthogService.identifyUserAndCurrentCompanyGroup(sessionMetadata)

      sentryService.setAppContext({
        currentUser: currentUser,
        currentCompany,
        currentUserType,
        isOutlook: isOfficeAddIn(),
      })

      dispatch(
        initialiseSessionSuccess({
          metadata: sessionMetadata,
        }),
      )

      // TODO: Move onboarding checklist fetch here too

      // Fetch RQ Certified Assessment
      if (
        sessionMetadata.currentCompany?.status === COMPANY_STATUS.CLAIMED &&
        sessionMetadata.currentCompany?.rqCertifiedAssessment
      ) {
        const assessmentIri =
          sessionMetadata.currentCompany.rqCertifiedAssessment

        await queryClient.fetchQuery({
          queryKey: QUERY_KEYS.rqCertifiedAssessment,
          queryFn: () =>
            rqCertifiedAssessmentService.getAssessmentByIri(assessmentIri),
        })
      }

      return sessionMetadata
    },
    onSuccess: (sessionMetadata) => {
      if (sessionMetadata?.commitHash) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.apiCommitHash,
          sessionMetadata.commitHash,
        )
      }
    },
    onError: () => {
      dispatch(initialiseSessionError())
    },
  })
}
