import './UploadFile.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleX } from '@rq-ratings/pro-solid-svg-icons'
import { UseMutationResult } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { Alert, Button, ProgressBar, Spinner } from 'react-bootstrap'

import VideoEmbed from '../../../../misc/VideoEmbed'

type ImageFileType = `image/${string}`
type VideoFileType = `video/${string}`
type FileTypeByExtension = `.${string}` // E,g, ".mkv", for which there's no "video/mkv" for some reason
type FileType = ImageFileType | VideoFileType | FileTypeByExtension

interface Props {
  /** A react-query mutation that will upload the file */
  uploadMutation: UseMutationResult<unknown, Error, File>
  /** 0-100, or null if no upload in progress */
  uploadProgressPercent?: number | null
  /** E.g. `['image/png', 'image/jpeg', 'image/gif']` or `['video/mp4', 'video/x-m4v', 'video/*']` */
  acceptableFileTypes: FileType[]
  /** What type of HTML element should be used to preview the file before upload? */
  previewType: 'image' | 'video'
  /** The URL we already have for the file this picker would replace  */
  currentFileUrl: string | null
  /** E.g. for a video, where it's been uploaded, but we haven't processed it yet */
  currentFileExistsButIsUnavailable: boolean
  /** This will be displayed when the user attempts to upload a file of an incorrect type */
  incorrectFileTypeMessage: string
}

/**
 * A nice UI for selecting, previewing and then uploading
 * (via tha uploadMutation prop) an image or a video.
 */
const UploadFile: React.FC<Props> = ({
  uploadMutation,
  uploadProgressPercent,
  acceptableFileTypes,
  previewType,
  currentFileUrl,
  currentFileExistsButIsUnavailable,
  incorrectFileTypeMessage,
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [isFileHovered, setIsFileHovered] = useState<boolean>(false)
  const [hoveredFileIsInvalid, setHoveredFileIsInvalid] =
    useState<boolean>(false)
  const [lastFileWasIncorrect, setLastFileWasIncorrect] =
    useState<boolean>(false)
  const [discardCurrentFile, setDiscardCurrentFile] = useState<boolean>(
    currentFileUrl === null && !currentFileExistsButIsUnavailable,
  )

  function isFileTypeValid(fileType: FileType): boolean {
    if (acceptableFileTypes.includes(fileType as FileType)) {
      return true
    }

    const fileTypePrefix = fileType.substring(0, fileType.indexOf('/'))

    // Deal with wildcards, like "video/*"
    return acceptableFileTypes.includes(`${fileTypePrefix}/*` as FileType)
  }

  function handleFileChange(newFile: File): void {
    if (!isFileTypeValid(newFile.type as FileType)) {
      setLastFileWasIncorrect(true)
      setFile(null)

      return
    }

    setLastFileWasIncorrect(false)
    setFile(newFile)

    uploadMutation.mutateAsync(newFile).catch(() => setFile(null))
  }

  /** Memoised because we don't want to keep rendering this every time progress changes */
  const preview = useMemo(renderPreview, [
    file,
    currentFileUrl,
    uploadMutation.isPending,
    previewType,
    currentFileExistsButIsUnavailable,
  ])

  function renderPreview() {
    const url = file ? URL.createObjectURL(file) : currentFileUrl

    if (!url && !currentFileExistsButIsUnavailable) {
      return null
    }

    return (
      <div className="position-relative border p-3 flex-grow-1">
        {!currentFileExistsButIsUnavailable && previewType === 'image' && (
          <img
            src={url as string}
            className="upload-file-preview-image"
            style={{
              objectFit: 'contain',
              width: '14rem',
              height: '14rem',
              transition: 'filter .3s ease, transform .3s ease',
              filter: uploadMutation.isPending ? 'blur(4px)' : 'none',
              transform: uploadMutation.isPending ? 'scale(0.5)' : 'scale(1)',
            }}
          />
        )}

        {previewType === 'video' && (
          <>
            {/* We're looking at the file that's just been selected by the user */}
            {file && (
              <video controls style={{ height: '14rem' }}>
                <source src={url as string} />
              </video>
            )}

            {/* The URL that comes from the API is for a Vimeo embed */}
            {!file && !currentFileExistsButIsUnavailable && (
              <VideoEmbed videoUrl={url as string} height={14 * 16} />
            )}

            {!file && currentFileExistsButIsUnavailable && (
              <div className="d-flex flex-column text-center justify-content-center p-3">
                <h2>Uploaded</h2>

                <p className="mb-0">
                  Previous upload has not yet been processed.
                </p>
                <p className="mb-0">This may take a few days.</p>
              </div>
            )}
          </>
        )}

        {!uploadMutation.isPending && (
          <div
            className="upload-file-preview-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ top: 0 }}
          >
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                setFile(null)
                setDiscardCurrentFile(true)
              }}
            >
              Change
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {(file !== null || !discardCurrentFile) && (
        <div className="d-flex flex-column gap-3 align-items-center mt-3">
          {preview}

          {uploadMutation.isPending && (
            <>
              <span>{' Uploading...'}</span>

              {(uploadProgressPercent === undefined ||
                uploadProgressPercent === null) && (
                <Spinner variant="secondary" animation="grow" size="sm" />
              )}

              {uploadProgressPercent !== undefined &&
                uploadProgressPercent !== null && (
                  <ProgressBar
                    now={uploadProgressPercent}
                    animated
                    className="mt-3 w-50"
                    label={`${Math.round(uploadProgressPercent)}%`}
                  />
                )}
            </>
          )}

          {uploadMutation.isSuccess && (
            <>
              <Alert variant="success" className="p-2">
                <p className="fs-3 mb-0">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-success"
                  />{' '}
                  Uploaded
                </p>
              </Alert>
            </>
          )}
        </div>
      )}

      {file === null && discardCurrentFile && (
        <>
          {/* THE FILE PICKER */}
          <div
            className={`upload-file-box rounded-4 position-relative d-flex flex-column align-items-center justify-content-center
              ${isFileHovered ? 'file-is-hovered' : ''}
              ${hoveredFileIsInvalid ? 'hovered-file-is-invalid' : ''}
            `}
          >
            <svg
              width="640"
              height="512"
              viewBox="0 0 640 512"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: '4rem', width: 'auto' }}
            >
              <path
                d="M0 304C0 383.5 64.5 448 144 448H512C582.7 448 640 390.7 640 320C640 258.1 596 206.4 537.6 194.6C541.7 183.8 544 172.2 544 160C544 107 501 64 448 64C428.3 64 409.9 70 394.7 80.2C367 32.2 315.3 0 256 0C167.6 0 96 71.6 96 160C96 162.7 96.1 165.4 96.2 168.1C40.2 187.8 0 241.2 0 304Z"
                className="back"
              />
              <path
                d="M223 231C213.6 240.4 213.6 255.6 223 264.9C232.4 274.2 247.6 274.3 256.9 264.9L295.9 225.9L296 360C296 373.3 306.7 384 320 384C333.3 384 344 373.3 344 360V225.9L383 264.9C392.4 274.3 407.6 274.3 416.9 264.9C426.2 255.5 426.3 240.3 416.9 231L336.9 151C327.5 141.6 312.3 141.6 303 151L223 231Z"
                className="front"
              />
            </svg>

            {isFileHovered && hoveredFileIsInvalid ? (
              <p className="pt-1 text-danger">Invalid file type</p>
            ) : (
              <p className="pt-1">
                Drag and drop a file, or click to browse for one
              </p>
            )}

            <input
              type="file"
              accept={acceptableFileTypes.join(', ')}
              onChange={(e) => {
                if (e.target.files === null) {
                  return
                }

                const file = e.target.files[0]

                if (file) {
                  handleFileChange(file)
                }
              }}
              onDrop={() => {
                setIsFileHovered(false)
              }}
              onDragEnter={(e) => {
                setIsFileHovered(true)

                const draggedItem =
                  e.dataTransfer.items.length > 0
                    ? e.dataTransfer.items[0]
                    : null

                if (draggedItem === null) {
                  return
                }

                if (!isFileTypeValid(draggedItem?.type as FileType)) {
                  e.dataTransfer.dropEffect = 'none'
                  setHoveredFileIsInvalid(true)
                } else {
                  e.dataTransfer.dropEffect = 'move'
                  setHoveredFileIsInvalid(false)
                }
              }}
              onDragLeave={() => {
                setIsFileHovered(false)
                setHoveredFileIsInvalid(false)
              }}
              className="position-absolute d-block h-100 w-100 opacity-0 top-0 start-0 cursor-pointer"
            />
          </div>

          {lastFileWasIncorrect && (
            <Alert variant="danger" className="p-2 mt-3 align-self-center">
              <p className="mb-0">
                <FontAwesomeIcon icon={faCircleX} className="text-danger" />{' '}
                {incorrectFileTypeMessage}
              </p>
            </Alert>
          )}

          {uploadMutation.isError && (
            <Alert variant="danger" className="p-2 mt-3 align-self-center">
              <p className="mb-0">
                <FontAwesomeIcon icon={faCircleX} className="text-danger" />{' '}
                Error uploading file. Please try again or choose another file.
              </p>
            </Alert>
          )}
        </>
      )}
    </>
  )
}

export default UploadFile
