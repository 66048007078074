import React, { Suspense } from 'react'
import { Search, Trello, TrendingUp } from 'react-feather'
import { useLocation } from 'react-router'

import { ROUTES } from '../../../lib/routes'
import Main from '../../misc/Main'
import PageContent from '../../misc/PageContent'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'
import LoadingWidget from '../../widgets/LoadingWidget'
import Footer from './components/Footer'
import Navbar from './components/Navbar/Navbar'
import Sidebar, { SidebarPage } from './components/sidebar/Sidebar'

interface Props {
  children: React.ReactNode
}

const IcaewDashboardLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation()

  function buildSidebarLinks(): SidebarPage[] {
    return [
      {
        href: ROUTES.icaewOverview,
        icon: <Trello />,
        title: 'Overview',
      },
      {
        href: ROUTES.icaewFirmSearch,
        icon: <Search />,
        title: 'Firm Search',
      },
      {
        href: ROUTES.icaewTrends,
        icon: <TrendingUp />,
        title: 'Trends',
      },
    ]
  }

  const sidebarLinks = buildSidebarLinks()

  return (
    <div className="wrapper">
      <Sidebar items={[{ pages: sidebarLinks }]} />

      <Main>
        <Navbar />
        <PageContent>
          <SentryErrorBoundary key={location.pathname}>
            <Suspense fallback={<LoadingWidget />}>{children}</Suspense>
          </SentryErrorBoundary>
        </PageContent>
        <Footer />
      </Main>
    </div>
  )
}

export default IcaewDashboardLayout
