import axios, { InternalAxiosRequestConfig } from 'axios'

import { getApiUrl } from '../helpers/apiHelpers'
import { buildUrl } from '../helpers/helperFunctions'
import { currentUrl } from '../helpers/routeHelpers'
import { ROUTES } from '../routes'
import authService from './authService'
import errorTypeService from './errorTypeService'
import sentryService from './sentryService'

const apiService = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
})

function setRefererPathHeader(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  if (!config.headers) {
    return config
  }

  config.headers['X-Referer-Path'] = (
    window.location.pathname + window.location.search
  ).substr(1)

  return config
}

function handleErrors(error: unknown) {
  if (errorTypeService.isTokenError(error)) {
    authService.logout()
    return
  }

  if (errorTypeService.isExpiredContextError(error)) {
    // Reload the page to refresh the user context
    window.location.reload()
    return
  }

  if (errorTypeService.isCloudflareChallengeResponse(error)) {
    window.location.assign(
      buildUrl(ROUTES.challenge, { redirectUri: currentUrl() }),
    )
  }

  sentryService.captureApiError(error)

  // Don't handle any other error types
  return Promise.reject(error)
}

apiService.interceptors.request.use(function (config) {
  return authService.setAuthHeader(config)
})
apiService.interceptors.request.use(setRefererPathHeader)
apiService.interceptors.response.use(
  // Any status code within the 2xx range will trigger this function
  (response) => response,

  // Any status codes outside the 2xx range will trigger this function
  (error) => handleErrors(error),
)

export default apiService
