import { useDebounce } from '@uidotdev/usehooks'
import React, { useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { CompanyItem } from '../../../../../../types/responses/companies'
import { useUpdateOtherServiceMutation } from '../hooks/useUpdateOtherServiceMutation'

interface Props {
  company: CompanyItem
  isIfaFirm: boolean
}

const OtherServicesField: React.FC<Props> = ({ company, isIfaFirm }) => {
  const [otherServices, setOtherServices] = useState(
    company.otherServices || '',
  )

  const debouncedOtherServices = useDebounce(otherServices, 400)

  const updateOtherServiceMutation = useUpdateOtherServiceMutation()

  useUpdateEffect(() => {
    updateOtherServiceMutation.mutate(debouncedOtherServices)
  }, [debouncedOtherServices])

  return (
    <section className={isIfaFirm ? 'mt-3 text-start' : 'mt-4'}>
      <label className="form-label">
        Do you offer other services that aren't listed?
      </label>

      <input
        type="text"
        className="form-control"
        value={otherServices}
        onChange={(event) => setOtherServices(event.target.value)}
        placeholder="Enter comma-separated list of services"
      />
    </section>
  )
}

export default OtherServicesField
