import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@rq-ratings/pro-regular-svg-icons'
import {
  faCheckCircle,
  faPlusCircle,
  faSquareCheck,
  faWarning,
} from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Button } from 'react-bootstrap'

import { formatDate } from '../../../lib/helpers/helperFunctions'
import {
  ReferralsImportItem,
  ReferralsImportOnClientDuplicate,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
}

const ClientCell: React.FC<Props> = ({ importItem, updateImportItem }) => {
  const handleChange = (value: ReferralsImportOnClientDuplicate) => {
    updateImportItem({ ...importItem, onClientDuplicate: value })
  }

  const isFirstNameDiff: boolean =
    importItem.fromClient &&
    !!importItem.sourceClientFirstName &&
    importItem.fromClient.firstName !== importItem.sourceClientFirstName
  const isLastNameDiff: boolean =
    importItem.fromClient &&
    !!importItem.sourceClientLastName &&
    importItem.fromClient.lastName !== importItem.sourceClientLastName
  const isTelephoneDiff: boolean =
    importItem.fromClient &&
    !!importItem.sourceClientTelephone &&
    importItem.fromClient.telephone !== importItem.sourceClientTelephone
  const isDateOfBirthDiff: boolean =
    importItem.fromClient &&
    !!importItem.sourceClientDateOfBirth &&
    importItem.fromClient.dateOfBirth !== importItem.sourceClientDateOfBirth

  const isDiff: boolean =
    isFirstNameDiff || isLastNameDiff || isTelephoneDiff || isDateOfBirthDiff
  const isUpdate = importItem.onClientDuplicate == 'update'

  return (
    <>
      {importItem.fromClient ? (
        <>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={`me-1 ${isDiff && isUpdate ? 'text-info' : 'text-success'}`}
          />
          <DiffValue
            isDiff={isFirstNameDiff && isUpdate}
            oldValue={importItem.fromClient.firstName}
            newValue={importItem.sourceClientFirstName}
          />
          {', '}
          <DiffValue
            isDiff={isLastNameDiff && isUpdate}
            oldValue={importItem.fromClient.lastName}
            newValue={importItem.sourceClientLastName}
          />
          <br />
          <span className="text-muted text-xs">
            {importItem.fromClient.email}
          </span>
          {(importItem.fromClient.dateOfBirth ||
            importItem.sourceClientDateOfBirth) && (
            <>
              <br />
              <span className="text-muted text-xs">
                <DiffValue
                  isDiff={isDateOfBirthDiff && isUpdate}
                  oldValue={
                    importItem.fromClient.dateOfBirth
                      ? formatDate(importItem.fromClient.dateOfBirth)
                      : 'undefined'
                  }
                  newValue={formatDate(importItem.sourceClientDateOfBirth)}
                />
              </span>
            </>
          )}
          {isDiff && (
            <span className="d-block show-on-row-hover">
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0 me-1"
                onClick={() =>
                  handleChange(
                    importItem.onClientDuplicate == 'keep-original'
                      ? 'update'
                      : 'keep-original',
                  )
                }
              >
                <FontAwesomeIcon
                  icon={isUpdate ? faSquareCheck : faSquare}
                  className="me-1"
                />
                update
              </Button>
              <span className="text-xs text-muted">
                {importItem.onClientDuplicate == 'keep-original'
                  ? '(keep existing)'
                  : ''}
              </span>
            </span>
          )}
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faPlusCircle} className="me-1 text-info" />
          {importItem.sourceClientFirstName}
          {', '}
          {importItem.sourceClientLastName}
          <br />
          <span className="text-muted text-xs">
            {importItem.sourceClientEmailValid === false && (
              <>
                <FontAwesomeIcon icon={faWarning} className="text-danger" />{' '}
              </>
            )}
            {importItem.sourceClientEmail}
            {importItem.sourceClientEmailValid === false && (
              <span className="text-danger"> (invalid)</span>
            )}
          </span>
          {importItem.sourceClientDateOfBirth && (
            <>
              <br />
              <span className="text-muted text-xs">
                {formatDate(importItem.sourceClientDateOfBirth)}
              </span>
            </>
          )}
        </>
      )}
    </>
  )
}

function DiffValue({
  isDiff,
  oldValue,
  newValue,
}: {
  isDiff: boolean
  oldValue: string
  newValue: string
}) {
  return (
    <>
      {isDiff ? (
        <>
          <span className="text-decoration-line-through opacity-75">
            {oldValue}
          </span>{' '}
          {newValue}
        </>
      ) : (
        oldValue
      )}
    </>
  )
}

export default ClientCell
