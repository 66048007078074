import { useQuery } from '@tanstack/react-query'
import React from 'react'

import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import { COMPANY_TYPE_CODE } from '../../../../../lib/constants'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import companyService from '../../../../../lib/services/companyService'
import LoadingWidget from '../../../../widgets/LoadingWidget'
import CompanyFcaServices from './components/CompanyFcaServices'
import IfaDetailsForm from './components/IfaDetailsForm/IfaDetailsForm'
import CompanyNonFcaServices from './components/nonFcaServices/CompanyNonFcaServices'

const CompanyServices: React.FC = () => {
  const currentCompany = useCurrentCompanyOrFail()
  const isIfaFirm = currentCompany.companyType.code === COMPANY_TYPE_CODE.ifa

  const companyQuery = useQuery({
    queryKey: QUERY_KEYS.company(currentCompany.id),
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: () => companyService.getCompany(currentCompany.id),
  })

  const company = companyQuery.data

  if (!company) {
    return <LoadingWidget />
  }

  return (
    <div>
      {currentCompany.isFcaFirm ? (
        <CompanyFcaServices company={company} isIfaFirm={isIfaFirm} />
      ) : (
        <CompanyNonFcaServices company={company} />
      )}

      {isIfaFirm && <IfaDetailsForm company={company} />}
    </div>
  )
}

export default CompanyServices
