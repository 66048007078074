import {
  PERSONAL_SITUATION_MARRIED,
  WITH_PARTNER_PERSONAL_SITUATIONS,
} from '../../../components/flows/CompassFlow/steps/5_ClientDetails/constants'
import { PARTNER_OR_SPOUSE_TERM } from '../../../components/flows/CompassFlow/utils/constants'
import { GetCompassPartnersParams } from '../../../types/requests/compass'
import {
  CompassPartnerCollection,
  CompassScores,
  CompassScoresWithSingleScore,
} from '../../../types/responses/compass'
import apiService from '../apiService'

class CompassService {
  private endpoint = '/v1/compass'

  hasSingleScore(
    scores?: Pick<
      CompassScores,
      'onTrackResilience' | 'organisedOptimised' | 'financialHealth'
    >,
  ): scores is CompassScoresWithSingleScore {
    return !!scores?.financialHealth
  }

  getPartnerOrSpouseTerm(personalSituation?: string): string {
    return personalSituation === PERSONAL_SITUATION_MARRIED
      ? PARTNER_OR_SPOUSE_TERM.spouse
      : PARTNER_OR_SPOUSE_TERM.partner
  }

  async getCompassPartners(
    companyId: number,
    params: GetCompassPartnersParams,
  ): Promise<CompassPartnerCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/partners`,
      { params },
    )

    return response.data
  }

  renderDate(options: { month?: number; year?: number }): string {
    const { month, year } = options

    if (!month || !year) {
      return 'N/A'
    }

    const date = new Date(year, month - 1, 1)

    return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`
  }

  hasSharedFinances(userPersonalSituation?: string): boolean {
    return !!(
      userPersonalSituation &&
      WITH_PARTNER_PERSONAL_SITUATIONS.includes(userPersonalSituation)
    )
  }
}

const compassService = new CompassService()

export default compassService
