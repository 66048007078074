import { cloneDeep } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import useAppSelector from '../../../hooks/useAppSelector'
import { selectReferralStatuses } from '../../../redux/slices/commonData'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
}

const StatusCell: React.FC<Props> = ({ importItem, updateImportItem }) => {
  const statuses = useAppSelector(selectReferralStatuses)
  const [isEdit, setEdit] = useState(false)
  const isSet = !!importItem.status

  const statusOptions: SelectableOption[] = useMemo((): SelectableOption[] => {
    return statuses.map((item) => {
      return {
        label: item.name,
        value: item.code,
      }
    })
  }, [statuses])
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newImportItem = cloneDeep(importItem)
    newImportItem.status = event.target.value
    updateImportItem(newImportItem)
    setEdit(false)
  }

  return (
    <>
      {!isEdit && isSet ? (
        <>
          <span className="cursor-pointer" onClick={() => setEdit(true)}>
            {
              statusOptions.find((item) => item.value === importItem.status)
                ?.label
            }
          </span>
          <span className="show-on-row-hover">
            {', '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setEdit(true)}
            >
              change
            </Button>
          </span>
        </>
      ) : (
        <Form.Select onChange={handleChange} value={importItem.status ?? ''}>
          {importItem.status ?? <option>Select status...</option>}
          {statusOptions.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </Form.Select>
      )}
    </>
  )
}

interface SelectableOption {
  label: string
  value: string
}
export default StatusCell

//
