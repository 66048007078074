import classNames from 'classnames'
import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

const Main: React.FC<Props> = ({ className, children }) => (
  <main className={classNames('main', className)}>{children}</main>
)

export default Main
