import { differenceInYears } from 'date-fns'
import { parse } from 'date-fns/parse'
import * as yup from 'yup'

import { DATE_FORMATS, MAX_INTEGER_VALUE, MAX_MONEY_VALUE } from '../constants'
import { formatMoney } from './helperFunctions'

export function number() {
  return yup.number().typeError('Must be a number')
}

export function positiveNumber() {
  return number().min(0)
}

export function validInteger() {
  return number()
    .min(0)
    .max(MAX_INTEGER_VALUE, () => {
      return `Please enter a value that is no greater than ${formatMoney(
        MAX_INTEGER_VALUE,
      )}`
    })
}

export function requiredBoolean() {
  return yup
    .bool()
    .test(
      'required-boolean',
      'This field is required',
      (value) => typeof value === 'boolean',
    )
}

export function validMoneyValue() {
  return number()
    .min(0)
    .max(MAX_MONEY_VALUE, () => {
      return `Please enter a value that is no greater than ${formatMoney(
        MAX_MONEY_VALUE,
      )}`
    })
}

export function dayMonthYear() {
  return yup
    .date()
    .transform((_value, originalValue) =>
      parse(originalValue, DATE_FORMATS.DAY_MONTH_YEAR, new Date()),
    )
    .typeError('Must be a valid date')
}

export const percentage = () => yup.number().min(0).max(100)

export function dateOfBirth(options: { minAge: number; maxAge: number }) {
  return yup
    .date()
    .transform((_value, originalValue) =>
      parse(originalValue, DATE_FORMATS.DAY_MONTH_YEAR, new Date()),
    )
    .typeError('Please enter a valid date')
    .test('invalid-date-of-birth', function (value) {
      if (!(value instanceof Date)) {
        throw new Error('Value must be an instance of Date')
      }

      const age = differenceInYears(new Date(), value)

      if (age < options.minAge) {
        return this.createError({
          message: `Invalid age: ${age}. Must be ${options.minAge} or older`,
        })
      }

      if (age > options.maxAge) {
        return this.createError({
          message: `Invalid age: ${age}. Must be ${options.maxAge} or younger`,
        })
      }

      return true
    })
}

export const yupSchemas = {
  number,
  dayMonthYear,
  percentage,
  positiveNumber,
  requiredBoolean,
  validMoneyValue,
  dateOfBirth,
}
