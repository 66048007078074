import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import React, { useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import useNotyf from '../../../hooks/useNotyf'
import { validateEmailAddress } from '../../../lib/helpers/emailAddressHelpers'
import EmailAddress from './EmailAddress'

interface Props {
  /**
   * Called whenever the list of email addresses changes
   *
   * @param newValue The new set of email addresses (potentially including invalid ones)
   * @param hasErrors `true` if `newValue` contains 1 or more invalid email address(es)
   */
  onChange: (newValue: string[], hasErrors: boolean) => void
  values: string[]
}

const regExpEndsWithSpaceCommaOrSemiColon = /.*(\s|;|,)$/g

/**
 * A nice UI for entering multiple email addresses.
 * - Allows **pasting** in a bunch of email addresses together (separated by `whitespace`,
 *   `,`, `|` or `;` (or a combination))
 * - Allows **typing** email addresses manually one at a time.
 * - Email addresses are tokenised (each with an X button to delete them with a click)
 *   as the user types. This breaks on ` `, `,`, `;`, `tab` and `enter`
 * - Provides a validation UI
 */
const EmailAddresses: React.FC<Props> = ({ onChange, values }) => {
  const [isFocussed, setIsFocussed] = useState<boolean>(false)

  const editableElemRef = useRef<HTMLDivElement>(null)

  const notyf = useNotyf()

  function handleInput(e: React.FormEvent<HTMLDivElement>): void {
    // We only really care about the last char, so can just reference textContent
    const textContent = e.currentTarget.textContent

    if (
      textContent !== null &&
      regExpEndsWithSpaceCommaOrSemiColon.test(textContent)
    ) {
      parseTypedInput()
    }
  }

  function handleChange(newValues: string[]): void {
    onChange(newValues, countErrors(newValues) > 0)
  }

  function parseTypedInput(): void {
    if (!editableElemRef.current) {
      return
    }

    const inputText = getTrailingText(true)

    if (inputText.length === 0) {
      return
    }

    const newList = [...values, inputText.trim()]

    handleChange(newList)

    editableElemRef.current.innerText = ''
  }

  function removeValueByIndex(index: number): void {
    const newList = [...values]

    newList.splice(index, 1)

    handleChange(newList)
  }

  function getTrailingText(trimmed = false): string {
    if (!editableElemRef.current) {
      return ''
    }

    return trimmed
      ? editableElemRef.current.innerText
          .trim()
          // These chars aren't valid for email addresses, and can be used as separators
          .replaceAll(',', '')
          .replaceAll(';', '')
      : editableElemRef.current.innerText
  }

  function removeInvalidValues(): void {
    const newValues = values.filter((value: string): boolean =>
      validateEmailAddress(value),
    )

    handleChange(newValues)
  }

  function countErrors(emailAddresses: string[]): number {
    return emailAddresses.filter(
      (emailAddress: string): boolean => !validateEmailAddress(emailAddress),
    ).length
  }

  const errorCount = countErrors(values)

  const borderClass =
    errorCount > 0 ? 'border-danger' : isFocussed ? 'border-primary' : ''

  return (
    <>
      <div
        className={`px-2 pt-2 pb-5 border rounded d-flex flex-wrap gap-1 position-relative cursor-text ${borderClass}`}
        onClick={(e) => {
          if ((e.target as HTMLElement).closest('.email-address')) return

          editableElemRef.current?.focus()
        }}
        onFocus={(e) => {
          if ((e.target as HTMLElement).closest('.email-address')) return

          editableElemRef.current?.focus()
        }}
      >
        <Form.Label className="py-1">To:</Form.Label>

        {/* Placeholder */}
        {values.length === 0 && !isFocussed && (
          <span aria-hidden className="opacity-50 pe-none ps-2 py-1">
            name@company.com
          </span>
        )}

        {values.map((value, i) => (
          <EmailAddress
            key={`${i}-${value}`}
            value={value}
            isValid={validateEmailAddress(value)}
            onClickRemove={() => removeValueByIndex(i)}
            onChange={(newValue: string) => {
              const newValues = [...values]

              newValues[i] = newValue

              handleChange(newValues)
            }}
          />
        ))}

        <span
          contentEditable
          ref={editableElemRef}
          className="py-1 ps-2"
          onInput={handleInput}
          onKeyDown={(e) => {
            switch (e.key) {
              case 'Backspace':
                if (getTrailingText().length === 0) {
                  removeValueByIndex(values.length - 1)
                }

                break

              case 'Enter':
              case 'Tab':
                if (getTrailingText().length === 0) {
                  return
                }

                // Don't tab away, or submit
                e.preventDefault()

                parseTypedInput()
                editableElemRef.current?.focus()

                break
            }
          }}
          onPaste={(e) => {
            e.preventDefault()

            const newValues = e.clipboardData
              .getData('text')
              .trim()
              // Can be comma, semi-colon, space or pipe separated, possibly with some wqhitespace in there too
              .split(/[,;\s |]+/)
              // 5, because that's the length of "a@b.c"
              .filter((value: string): boolean => value.length >= 5)

            const finalNewValues = newValues
              // Don't bother inserting anything that's already in there
              .filter((emailAddress: string) => !values.includes(emailAddress))

            const countDuplicateValues =
              newValues.length - finalNewValues.length

            let message = 'Pasted.'

            if (countDuplicateValues > 0) {
              message += ` ${countDuplicateValues} email address${countDuplicateValues === 1 ? '' : 'es'} were already in this list.`
            }

            notyf.success(message)

            // Add them to the list
            handleChange([...values, ...finalNewValues])
          }}
          onFocus={() => {
            setIsFocussed(true)
          }}
          onBlur={() => {
            setIsFocussed(false)
            parseTypedInput()
          }}
          style={{ outline: 'none' }}
        />
      </div>

      {/* Show validation */}
      {errorCount > 0 && (
        <p className="text-end mt-2 text-danger">
          <FontAwesomeIcon icon={faTriangleExclamation} className="pe-1" />
          {`${errorCount} ${errorCount === 1 ? 'error' : 'errors'}. `}
          <Button variant="link" className="p-0" onClick={removeInvalidValues}>
            Remove all items with errors
          </Button>
        </p>
      )}
    </>
  )
}

export default EmailAddresses
