import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { parseISO } from 'date-fns/parseISO'
import invariant from 'tiny-invariant'

import useAppDispatch from '../../../hooks/useAppDispatch'
import {
  DEFAULT_BULK_IMPORT_CIRCUMSTANCES,
  DEFAULT_BULK_IMPORT_REASON,
} from '../../../lib/constants'
import { isNotProduction } from '../../../lib/helpers/envHelpers'
import {
  getErrorDebugInfo,
  isConstraintViolationListError,
} from '../../../lib/helpers/helperFunctions'
import clientService from '../../../lib/services/clientService'
import constraintViolationService from '../../../lib/services/constraintViolationService'
import referralService from '../../../lib/services/referralService'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import {
  updateImportItem,
  updateImportItemStatus,
} from '../../../redux/slices/referralsImportHistorical'
import { FormErrors } from '../../../types/misc'
import { CreateClientForReferralRequest } from '../../../types/requests/clients'
import {
  CreateHistoricalReferralRequest,
  CreateReferralRequest,
} from '../../../types/requests/referrals'
import { formatApiErrors } from '../../flows/CompassFlow/utils/helpers'

export function useImportHistoricalReferralMutation() {
  const dispatch = useAppDispatch()

  return useMutation({
    // retry mutation up to 4 times with linear backoff delay (last delay 3000ms, total time 7.5s)
    retry: 4,
    retryDelay: (attempt) => attempt * 750,
    mutationFn: async (importItem: ReferralsImportItem) => {
      dispatch(
        updateImportItemStatus({
          refUUID: importItem.refUUID,
          importStatus: 'processing',
        }),
      )
      // create client if missing
      let request: CreateReferralRequest
      if (importItem.fromClient === null) {
        const newClient =
          await clientService.createClientForReferralOrFindExisting(
            buildCreateClientRequest(
              importItem.sourceClientFirstName,
              importItem.sourceClientLastName,
              importItem.sourceClientEmail,
              importItem.sourceClientTelephone,
              importItem.sourceClientDateOfBirth,
            ),
            importItem.fromCompany.id,
          )
        // import with new client
        dispatch(
          updateImportItem({
            ...importItem,
            fromClient: newClient,
            importStatus: 'processing',
          }),
        )
        request = buildCreateReferralRequest({
          ...importItem,
          fromClient: newClient,
        })
      } else {
        // eventually update existing client
        if (importItem.onClientDuplicate === 'update') {
          await clientService.updateClientForReferral(
            importItem.fromClient.id,
            buildCreateClientRequest(
              importItem.sourceClientFirstName,
              importItem.sourceClientLastName,
              importItem.sourceClientEmail,
              importItem.sourceClientTelephone,
              importItem.sourceClientDateOfBirth,
            ),
          )
        }
        // import with existing client
        request = buildCreateReferralRequest(importItem)
      }
      return referralService.createReferral(request)
    },
    onError: async (error, importItem) => {
      let formattedErrors: FormErrors | undefined
      if (isConstraintViolationListError(error)) {
        const apiErrors = constraintViolationService.formatErrors(error)
        formattedErrors = formatApiErrors(apiErrors)
      } else {
        if (error instanceof AxiosError) {
          formattedErrors = {
            error: error.response?.data?.detail ?? error.message,
          }
        } else if (error.message) {
          formattedErrors = {
            error: error.message.replace('Invariant failed: ', ''),
          }
        } else {
          formattedErrors = {
            error: 'An error occurred',
          }
        }
        if (isNotProduction()) {
          formattedErrors.debug = JSON.stringify(getErrorDebugInfo(error))
        }
      }
      dispatch(
        updateImportItemStatus({
          refUUID: importItem.refUUID,
          importStatus: 'error',
          importErrors: formattedErrors,
        }),
      )
    },
    onSuccess: async (_data, importItem) => {
      dispatch(
        updateImportItemStatus({
          refUUID: importItem.refUUID,
          importStatus: 'imported',
          importErrors: undefined,
        }),
      )
    },
  })
}

function buildCreateClientRequest(
  firstName: string,
  lastName: string,
  email: string,
  telephone: string,
  dateOfBirth?: string,
): CreateClientForReferralRequest {
  return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    telephone: telephone,
    dateOfBirth:
      dateOfBirth && !isNaN(parseISO(dateOfBirth).getTime())
        ? dateOfBirth
        : undefined,
    clientId: '',
  }
}

function buildCreateReferralRequest(
  importItem: ReferralsImportItem,
): CreateHistoricalReferralRequest {
  invariant(importItem.fromClient.id, 'Expected client to be created')
  invariant(importItem.serviceArea, 'Expected service area to be set')

  return {
    fromCompany: `/v1/companies/${importItem.fromCompany.id}`,
    toCompany: `/v1/companies/${importItem.toCompany.id}`,
    toCompanyUsers: importItem.toCompanyUser
      ? [`/v1/users/${importItem.toCompanyUser.id}`]
      : [],
    circumstances:
      importItem.sourceCircumstances ?? DEFAULT_BULK_IMPORT_CIRCUMSTANCES,
    client: importItem.fromClient['@id'],
    reason: DEFAULT_BULK_IMPORT_REASON,
    type: 'indirect', // Direct will often be blocked by logic set out in API getAvailableReferralTypes()
    serviceAreaIds: [importItem.serviceArea?.id ?? 0],
    shouldCcClientOnInitialEmail: false,
    hasClientConsent: false,
    isImport: true,
    // new fields
    isHistoricalReferral: true,
    isEraOverride: importItem.isEraOverride,
    importStatusCode: importItem.status ?? undefined,
    referralDate: importItem.referralDate ?? undefined,
  }
}
