import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router'

import { useHomeRoute } from '../../../../hooks/useHomeRoute'
import useMaxWidth from '../../../../hooks/useMaxWidth'

interface Props {
  children: React.ReactNode
}

const HomepageLink: React.FC<Props> = ({ children }) => {
  const homeRoute = useHomeRoute()
  const isMaxWidthMd = useMaxWidth('md')

  return (
    <OverlayTrigger
      overlay={<Tooltip>You can come back here at any time.</Tooltip>}
      placement="bottom"
    >
      <Link to={homeRoute} className={isMaxWidthMd ? 'pt-3 px-3' : 'pt-4 px-4'}>
        {children}
      </Link>
    </OverlayTrigger>
  )
}

export default HomepageLink
