import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface ReferralSignatureConsentPdfState {
  signature: string
  hasSubmittedSignature: boolean
  hasConfirmedESignatureUnderstanding: boolean
  isShowingSubmitModal: boolean
  isSignable: boolean
}

const INITIAL_STATE: ReferralSignatureConsentPdfState = {
  signature: '',
  hasSubmittedSignature: false,
  hasConfirmedESignatureUnderstanding: false,
  isShowingSubmitModal: false,
  isSignable: false,
}

export const referralESignatureConsentPdf = createSlice({
  name: 'referralESignatureConsentPdf',
  initialState: INITIAL_STATE,
  reducers: {
    setSignature(
      state,
      action: PayloadAction<ReferralSignatureConsentPdfState['signature']>,
    ) {
      state.signature = action.payload
    },

    clearSignature(state) {
      state.signature = INITIAL_STATE.signature
    },

    setHasSubmittedSignature(state, action: PayloadAction<boolean>) {
      state.hasSubmittedSignature = action.payload
    },

    setHasConfirmedESignatureUnderstanding(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.hasConfirmedESignatureUnderstanding = action.payload
    },

    setIsShowingSubmitModal(state, action: PayloadAction<boolean>) {
      state.isShowingSubmitModal = action.payload
    },

    setIsSignable(state, action: PayloadAction<boolean>) {
      state.isSignable = action.payload
    },
  },
})

export const selectReferralESignatureConsentPdf = (state: RootState) =>
  state.referralSignatureConsentPdf

export const selectSignature = createSelector(
  selectReferralESignatureConsentPdf,
  (state) => state.signature,
)

export const selectIsShowingSubmitModal = createSelector(
  selectReferralESignatureConsentPdf,
  (state) => state.isShowingSubmitModal,
)

export const selectHasConfirmedESignatureUnderstanding = createSelector(
  selectReferralESignatureConsentPdf,
  (state) => state.hasConfirmedESignatureUnderstanding,
)

export const selectIsSignable = createSelector(
  selectReferralESignatureConsentPdf,
  (state) => state.isSignable && !state.hasSubmittedSignature,
)

export const selectHasSignedLetter = createSelector(
  selectSignature,
  (signature) => !!signature,
)

export const selectHasSubmittedSignature = createSelector(
  selectReferralESignatureConsentPdf,
  (state) => !!state.hasSubmittedSignature,
)

export const {
  setSignature,
  clearSignature,
  setHasSubmittedSignature,
  setIsShowingSubmitModal,
  setHasConfirmedESignatureUnderstanding,
  setIsSignable,
} = referralESignatureConsentPdf.actions

export const referralESignatureConsentPdfReducer =
  referralESignatureConsentPdf.reducer
