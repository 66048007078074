import React from 'react'

export type ToggleOption = {
  label: React.ReactNode
  value: string
}

type Props = {
  options: ToggleOption[]
  value: string | null
  onChange: (value: string) => void
  direction?: 'horizontal' | 'vertical'
  enabled?: boolean
  className?: string
}

export const ToggleMultiple = ({
  options = [],
  value = null,
  onChange,
  direction = 'vertical',
  enabled = true,
  className = '',
}: Props) => {
  return (
    <div
      className={
        'd-flex gap-2 ' +
        (direction === 'horizontal' ? 'flex-row flex-wrap ' : 'flex-column ') +
        className
      }
    >
      {options.map((option) => (
        <label
          key={option.value}
          className={
            'd-flex flex-row gap-2  align-items-center px-2' +
            (enabled ? ' cursor-pointer' : ' pointer-events-none')
          }
          htmlFor={option.value}
        >
          <input
            id={option.value}
            name={option.value}
            checked={option.value === value}
            type="radio"
            disabled={!enabled}
            value={option.value}
            className="form-check-input m-0"
            style={{ minWidth: '16px', minHeight: '16px' }}
            onChange={() => {
              onChange(option.value)
            }}
          />
          <span
            className={'d-block flex-grow-1 ' + (enabled ? '' : 'opacity-50')}
          >
            {option.label}
          </span>
        </label>
      ))}
    </div>
  )
}
