import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

import useAppSelector from '../../../hooks/useAppSelector'
import { selectServiceAreas } from '../../../redux/slices/commonData'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  afterBlockElement?: boolean
}

const ServiceMissingWarning: React.FC<Props> = ({
  importItem,
  afterBlockElement = false,
}) => {
  const serviceAreas = useAppSelector(selectServiceAreas())
  const selectedServiceArea = serviceAreas?.find(
    (serviceArea) => serviceArea.id === importItem.sourceServiceAreaId,
  )
  return (
    <>
      {!afterBlockElement && (
        <>
          ,<br />
        </>
      )}
      <span className="text-warning text-xs">
        <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
        {selectedServiceArea ? (
          <>
            you selected “
            <span className="font-weight-600">{selectedServiceArea?.name}</span>
            ”, but this service is not provided by{' '}
            <span className="font-weight-600">{importItem.toCompany.name}</span>
            , please select a different service or keep default
          </>
        ) : (
          <>please select service</>
        )}
      </span>
    </>
  )
}

export default ServiceMissingWarning
