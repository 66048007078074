import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface CompleteRegistrationPopupState {
  isEditing: boolean
  companyServices: {
    isValid: boolean
  }
}

const INITIAL_STATE: CompleteRegistrationPopupState = {
  isEditing: false,
  companyServices: {
    isValid: false,
  },
}

const completeRegistrationPopup = createSlice({
  name: 'completeRegistrationPopup',
  initialState: INITIAL_STATE,
  reducers: {
    startEdit: (state) => {
      state.isEditing = true
    },
    endEdit: (state) => {
      state.isEditing = false
    },
    setIsCompanyServicesValid: (
      state,
      action: PayloadAction<
        CompleteRegistrationPopupState['companyServices']['isValid']
      >,
    ) => {
      state.companyServices.isValid = action.payload
    },
  },
})

export const { startEdit, endEdit, setIsCompanyServicesValid } =
  completeRegistrationPopup.actions

const selectCompleteRegistrationPopup = (state: RootState) =>
  state.completeRegistrationPopup

export const selectIsEditing = createSelector(
  selectCompleteRegistrationPopup,
  (state) => state.isEditing,
)

export const selectCompanyServices = createSelector(
  selectCompleteRegistrationPopup,
  (state) => state.companyServices,
)

export const selectIsCompanyServicesValid = createSelector(
  selectCompanyServices,
  (companyServices) => companyServices.isValid,
)

export const completeRegistrationPopupReducer =
  completeRegistrationPopup.reducer
