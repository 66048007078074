import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'

import useAppSelector from '../../../../../../../hooks/useAppSelector'
import { useCurrentCompanyServicesQuery } from '../../../../../../../hooks/useCompanyServicesQuery'
import { useCompanyType } from '../../../../../../../hooks/useCompanyType'
import {
  selectServiceAreas,
  selectServiceAreasByCompanyTypeCode,
} from '../../../../../../../redux/slices/commonData'
import { CompanyItem } from '../../../../../../../types/responses/companies'
import { ServiceAreaItem } from '../../../../../../../types/responses/service-area'
import LoadingCard from '../../../../../../misc/LoadingCard'
import ResultsSection from './ResultsSection'
import ServicesSearchForm from './ServicesSearchForm'
import SuggestNewServiceArea from './SuggestNewServiceArea'

interface Props {
  company: CompanyItem
}

const CompanyNonFcaServices: React.FC<Props> = ({ company }) => {
  const companyType = useCompanyType()
  const companyTypeServiceAreas = useAppSelector(
    selectServiceAreasByCompanyTypeCode(companyType.code),
  )

  const serviceAreas = useAppSelector(selectServiceAreas(false))

  const [searchTerm, setSearchTerm] = useState<string>('')

  const companyServicesQuery = useCurrentCompanyServicesQuery()

  const companyServices = companyServicesQuery.data

  if (companyServicesQuery.isLoading || !companyServices) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <LoadingCard />
      </div>
    )
  }

  const companyTypeServiceAreaIds = companyTypeServiceAreas.map(
    (serviceArea) => serviceArea.id,
  )

  const otherServiceAreas = serviceAreas.filter(
    (serviceArea: ServiceAreaItem) =>
      !companyTypeServiceAreaIds.includes(serviceArea.id),
  )

  function serviceAreaIsSearchMatch(serviceArea: ServiceAreaItem): boolean {
    for (const searchWord of searchTerm.split(' ')) {
      if (!serviceArea.name.toLowerCase().includes(searchWord.toLowerCase())) {
        return false
      }
    }

    return true
  }

  const filteredCompanyTypeServiceAreas = companyTypeServiceAreas.filter(
    serviceAreaIsSearchMatch,
  )

  const filteredOtherServiceAreas = otherServiceAreas.filter(
    serviceAreaIsSearchMatch,
  )

  const totalResultsLength =
    filteredCompanyTypeServiceAreas.length + filteredOtherServiceAreas.length

  return (
    <section className="d-flex flex-column align-items-center justify-content-center h-100 pop-in">
      <ServicesSearchForm
        searchTerm={searchTerm}
        onChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
        selectedServices={companyServices}
        otherServicesString={company.otherServices ?? ''}
      />

      {companyServicesQuery.isPending ? (
        <Spinner />
      ) : (
        <>
          <ResultsSection
            results={filteredCompanyTypeServiceAreas}
            headingText="Suggested services for your firm"
            searchTerm={searchTerm}
            totalResultsLength={totalResultsLength}
          />

          {searchTerm !== '' && (
            <ResultsSection
              results={filteredOtherServiceAreas}
              headingText="Other services"
              searchTerm={searchTerm}
              totalResultsLength={totalResultsLength}
            />
          )}

          {searchTerm !== '' && totalResultsLength === 0 && (
            <SuggestNewServiceArea
              inputText={searchTerm}
              currentOtherServicesString={company.otherServices ?? ''}
              onSave={() => setSearchTerm('')}
            />
          )}
        </>
      )}
    </section>
  )
}

export default CompanyNonFcaServices
