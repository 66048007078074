import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import SentryErrorBoundary from './components/misc/SentryErrorBoundary'
import { registerGlobalEventListeners } from './lib/globalEventListeners'
import posthogService from './lib/services/posthogService'
import sentryService from './lib/services/sentryService'

registerGlobalEventListeners()

sentryService.init()
posthogService.init()

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <SentryErrorBoundary variant="full-screen">
    <App />
  </SentryErrorBoundary>,
)
