import './bootstrap'

import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import React, { Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router'
import { Slide, ToastContainer } from 'react-toastify'

import RootError from './components/misc/RootError'
import SentryErrorBoundary from './components/misc/SentryErrorBoundary'
import LoadingWidget from './components/widgets/LoadingWidget'
import { LayoutProvider } from './contexts/LayoutContext'
import { SidebarProvider } from './contexts/SidebarContext'
import { ThemeProvider } from './contexts/ThemeContext'
import queryClient from './lib/queryClient'
import { store } from './redux/store'
import Root from './Root'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '*',
    element: <Root />,
    errorElement: (
      <SentryErrorBoundary variant="full-screen">
        <RootError />
      </SentryErrorBoundary>
    ),
  },
])

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Helmet
          titleTemplate="%s | RQ - Deepen your professional collaboration"
          defaultTitle="RQ - Deepen your professional collaboration"
        />
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <Suspense fallback={<LoadingWidget variant="black" />}>
              <SidebarProvider>
                <LayoutProvider>
                  <RouterProvider router={router} />
                </LayoutProvider>

                <ToastContainer
                  hideProgressBar
                  theme="colored"
                  transition={Slide}
                />
              </SidebarProvider>
            </Suspense>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  )
}

export default App
