import { DeepPartial, INotyfNotificationOptions } from 'notyf/notyf.options'
import * as React from 'react'
import { Navigate } from 'react-router'
import invariant from 'tiny-invariant'

import { useAuth } from '../../hooks/useAuth'
import useNotyf from '../../hooks/useNotyf'

interface Props {
  children: React.ReactNode
  guard: boolean
  onDenied: RedirectOptions | (() => void)
}

interface RedirectOptions {
  redirect: {
    to: string
    notification?: DeepPartial<INotyfNotificationOptions>
  }
}

// Use to prevent access to routes if a condition is not met
const AccessControlGuard: React.FC<Props> = ({ children, guard, onDenied }) => {
  const { isInitialized } = useAuth()
  const notyf = useNotyf()

  invariant(
    isInitialized,
    'AccessControlGuard should not be rendered until session has been initialized',
  )

  // Guard passes so render children
  if (guard) {
    return <>{children}</>
  }

  // Handle onDenied callback
  if (typeof onDenied === 'function') {
    onDenied()
    return null
  }

  // Handle redirect options
  const { redirect } = onDenied
  if (redirect.notification) {
    notyf.open(redirect.notification)
  }

  return <Navigate to={redirect.to} replace />
}

export default AccessControlGuard
