import React from 'react'
import { Link, useLocation } from 'react-router'

import useAppSelector from '../../../../../hooks/useAppSelector'
import { useRqCertifiedAssessmentOrFail } from '../../../../../hooks/useRqCertifiedAssessmentQuery'
import { RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE } from '../../../../../lib/constants'
import { buildBookMeetingUrl } from '../../../../../pages/bookMeeting/helpers'
import { selectCurrentCompanyOrFail } from '../../../../../redux/slices/session'
import AssessmentTwoNotStartedButtons from './AssessmentTwoNotStartedButtons'
import ContinueApplicationButtons from './ContinueApplicationButtons'
import PendingPaymentConfirmationButtons from './PendingPaymentConfirmationButtons'
import StartApplicationButtons from './StartApplicationButtons'

interface Props {
  children?: React.ReactNode
}

const RqCertifiedActionButtons: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation()
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)
  const assessment = useRqCertifiedAssessmentOrFail()

  const isYetToStartApplication =
    assessment.isAtStageOne &&
    assessment.currentStatus === RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.notStarted

  const isPendingPaymentConfirmation =
    assessment.currentStatus ===
    RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.pendingPaymentConfirmation

  const canContinueApplication =
    assessment.currentStatus === RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.inProgress

  const hasJustPassedAssessmentOne =
    assessment.assessmentOneStatus ===
      RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.passed &&
    assessment.assessmentTwoStatus ===
      RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.notStarted

  function buildButtons(): React.ReactNode {
    if (!currentCompany.isRqRated && isYetToStartApplication) {
      return <StartApplicationButtons />
    }

    if (isPendingPaymentConfirmation) {
      return <PendingPaymentConfirmationButtons />
    }

    if (canContinueApplication) {
      return <ContinueApplicationButtons />
    }

    if (hasJustPassedAssessmentOne) {
      return <AssessmentTwoNotStartedButtons />
    }

    return null
  }

  const buttons = buildButtons()

  return (
    <>
      <section className="d-flex flex-column flex-md-row justify-content-md-end align-items-center gap-2 gap-md-3 mt-4">
        {children}

        {buttons}

        <Link
          className="btn btn-outline-primary btn-fluid"
          to={buildBookMeetingUrl({
            backLabel: 'Back to RQ Certified',
            backTo: pathname,
          })}
        >
          Discuss it with us
        </Link>
      </section>
    </>
  )
}

export default RqCertifiedActionButtons
