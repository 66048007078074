import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router'

import { useAppState } from '../../../../hooks/useAppState'
import { useClientMetadata } from '../../../../hooks/useClientMetadata'
import { useCreateStateMutation } from '../../../../hooks/useCreateStateMutation'
import { STATE_KEY, STATE_TYPE } from '../../../../lib/constants'
import { ROUTES } from '../../../../lib/routes'
import ActionButton from '../../../misc/ActionButton'

const ReviewPartnerModal: React.FC = () => {
  const [showing, setShowing] = useState(true)
  const appState = useAppState()
  const createUserStateMutation = useCreateStateMutation()
  const navigate = useNavigate()

  const clientMetadata = useClientMetadata()

  if (
    appState.hasClientReviewedPartners ||
    clientMetadata?.sponsorCompany?.hasPanel !== true ||
    !clientMetadata.hasCompleteCompassReport
  ) {
    return null
  }

  const hideModal = () => {
    setShowing(false)
    if (!appState.hasClientReviewedPartners) {
      createUserStateMutation.mutate({
        stateKey: STATE_KEY.hasClientReviewedPartners,
        stateValue: true,
        stateType: STATE_TYPE.user,
      })
    }
  }

  return (
    <Modal show={showing} onHide={hideModal} centered>
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <p>
          Based on your snapshot, we recommend reviewing our selected partners.
          You can see a list of different professions via the{' '}
          <b>Our Partners</b> tab.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <ActionButton
          variant="primary"
          className="float-start"
          isProcessing={createUserStateMutation.isPending}
          onClick={() => {
            hideModal()
            navigate(ROUTES.compassPartners)
          }}
        >
          See Our Partners
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ReviewPartnerModal
