import React from 'react'
import { Card } from 'react-bootstrap'

import LoadingWidget from '../widgets/LoadingWidget'

const LoadingCard: React.FC = () => (
  <Card>
    <Card.Body>
      <LoadingWidget />
    </Card.Body>
  </Card>
)

export default LoadingCard
