import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import { cloneDeep } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
  bulkUpdateAdvisors: (
    company: ReferralsImportItem['toCompany'],
    user: ReferralsImportItem['toCompanyUser'],
  ) => void
}

const AdvisorCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateAdvisors,
}) => {
  const [change, setChange] = useState(false)

  const advisorsOptions: SelectableOption[] =
    useMemo((): SelectableOption[] => {
      return importItem.toCompany.users
        .map((user) => {
          return {
            label:
              user.firstName + ' ' + user.lastName + ' (' + user.email + ')',
            value: user.id,
          }
        })
        .filter(
          (value, index, array) =>
            array.findIndex((a) => a.value == value.value) === index,
        )
    }, [importItem])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newImportItem = cloneDeep(importItem)
    newImportItem.toCompanyUser =
      importItem.toCompany.users.find(
        (user) => user.id === +event.target.value,
      ) || null
    newImportItem.toCompanyUserDefaultUsed = false
    updateImportItem(newImportItem)
    setChange(false)
  }

  const handleBulkApply = () => {
    bulkUpdateAdvisors(importItem.toCompany, importItem.toCompanyUser)
  }

  if (change) {
    return (
      <>
        <Form.Select
          onChange={handleChange}
          defaultValue={importItem.toCompanyUser?.id}
        >
          {advisorsOptions.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </Form.Select>
      </>
    )
  } else if (importItem.toCompanyUser) {
    return (
      <span className="text-muted text-xs">
        <span
          onClick={() => setChange(true)}
          className="font-weight-600 cursor-pointer"
        >
          {importItem.toCompanyUser.firstName}{' '}
          {importItem.toCompanyUser.lastName}
        </span>
        , {importItem.toCompanyUser.email}
        {importItem.toCompanyUserDefaultUsed && (
          <>
            <span className="text-warning"> (default)</span>
          </>
        )}
        {importItem.toCompanyUserDefaultUsed &&
          importItem.sourceAdvisorEmail != '' && (
            <>
              ,<br />
              <span className="text-warning " style={{ maxWidth: '300px' }}>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-1"
                />
                advisor with email “{importItem.sourceAdvisorEmail}” not found,
                select from available advisors or leave default
              </span>
            </>
          )}
        <span className="show-on-row-hover">
          ,{' '}
          <Button
            variant="link"
            size="sm"
            className="text-xs p-0 m-0"
            onClick={() => setChange(true)}
          >
            change
          </Button>
          {importItem.bulkCompanyCount > 1 ? (
            <>
              {' or '}
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0"
                onClick={handleBulkApply}
              >
                apply&nbsp;this&nbsp;advisor&nbsp;for&nbsp;all&nbsp;referrals&nbsp;for&nbsp;this&nbsp;company&nbsp;(
                {importItem.bulkCompanyCount})
              </Button>
            </>
          ) : (
            <></>
          )}
        </span>
      </span>
    )
  } else if (advisorsOptions.length > 0) {
    return (
      <span className="text-muted text-xs">
        No specific advisor selected
        <span className="show-on-row-hover">
          ,{' '}
          <Button
            variant="link"
            size="sm"
            className="text-xs p-0 m-0"
            onClick={() => setChange(true)}
          >
            choose
          </Button>
        </span>
      </span>
    )
  } else {
    return (
      <>
        <div className="text-muted text-xs mt-1">
          <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
          Not possible to select advisor. No advisors available for this
          company.
        </div>
      </>
    )
  }
}

interface SelectableOption {
  label: string
  value: number
}

export default AdvisorCell
