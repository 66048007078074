import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import fileDownload from 'js-file-download'
import React, { useRef, useState } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'

import { ImportReferralsError } from '../../../contexts/ImportReferralsContext'
import useAppSelector from '../../../hooks/useAppSelector'
import useImportReferralsContext from '../../../hooks/useImportReferralsContext'
import useModal from '../../../hooks/useModal'
import useNotyf from '../../../hooks/useNotyf'
import { IMPORT_FILE_NOTIFICATION_DURATION } from '../../../lib/constants'
import apiService from '../../../lib/services/apiService'
import { selectCurrentCompanyOrFail } from '../../../redux/slices/session'
import { FileUploadBox, FileUploadBoxState } from '../../misc/FileUploadBox'
import Step from '../../misc/Step'
import { ToggleMultiple, ToggleOption } from '../../misc/ToggleMultiple'

interface Props {
  className?: string
}

const UploadGeneral: React.FC<Props> = ({ className = '' }) => {
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)
  const [overwriteDuplicates, setOverwriteDuplicates] = useState(false)
  const {
    file,
    uploadStatus,
    uploadError,
    setFile,
    setUploadStatus,
    setUploadError,
  } = useImportReferralsContext()
  const notyf = useNotyf()
  const [isErrorMessageExpanded, setIsErrorMessageExpanded] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const {
    isShowingModal: isShowingFinishedModal,
    showModal: showFinishedModal,
    hideModal: hideFinishedModal,
  } = useModal()

  let uploadBoxMessage = 'Ready to upload a list of your referrals?'
  let uploadBoxState: FileUploadBoxState = 'enabled'
  if (uploadStatus === 'uploading') {
    uploadBoxState = 'uploading'
    uploadBoxMessage = 'Uploading...'
  } else if (uploadStatus === 'success') {
    uploadBoxState = 'success'
    uploadBoxMessage = 'Data has been uploaded successfully'
  } else if (uploadStatus === 'error') {
    uploadBoxState = 'error'
    uploadBoxMessage = 'Upload failed'
  }

  // handlers
  const handleTemplateDownload = async () => {
    const fileName = 'import-historical-referrals-general-template.xlsx'
    const response = await apiService.get(
      `v1/companies/${currentCompany.id}/download-general-referrals-template`,
      {
        responseType: 'blob',
      },
    )
    fileDownload(response.data, fileName)
  }
  const handleDuplicateOptionChange = (value: string) => {
    setOverwriteDuplicates(value === 'true')
  }
  const handleFileChange = (file: File) => {
    setFile(file)
    setUploadStatus('idle')
  }
  const handleFileCancel = () => {
    setFile(null)

    if (inputFileRef.current?.value) {
      inputFileRef.current.value = ''
    }

    setUploadStatus('idle')
  }

  const handleUpload = async () => {
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('overwrite', overwriteDuplicates ? '1' : '0')

    setUploadStatus('uploading')
    setUploadError(null)
    setIsErrorMessageExpanded(false)

    apiService
      .post(
        `v1/companies/${currentCompany.id}/email-import-referrals`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        handleFileCancel()
        setUploadStatus('success')

        notyf.success({
          message: 'Referrals successfully uploaded',
          duration: IMPORT_FILE_NOTIFICATION_DURATION,
        })

        showFinishedModal()

        setTimeout(() => {
          setUploadStatus('idle')
        }, IMPORT_FILE_NOTIFICATION_DURATION)
      })
      .catch((error) => {
        setUploadStatus('error')

        const uploadError: ImportReferralsError = {
          message: error.response.data['hydra:description'] ?? '',
          fileName: file.name,
        }

        if (uploadError.message === '') {
          uploadError.message = error.response.statusText ?? 'Unknown error'
        }

        setUploadError(uploadError)
        setIsErrorMessageExpanded(true)

        setTimeout(() => {
          handleFileCancel()
        }, IMPORT_FILE_NOTIFICATION_DURATION)
      })
  }

  return (
    <div className={' ' + className}>
      <Step step="1" className="mt-0">
        <p className="mb-2">
          Download our template spreadsheet and fill in your historical
          referrals.
        </p>
        <button
          className="btn d-block btn-primary"
          onClick={() => handleTemplateDownload()}
        >
          Download template
        </button>
      </Step>
      <Step step="2">
        <p className="mb-2">Upload the completed spreadsheet.</p>

        <p className="mb-2">
          If your import contains duplicated clients, RQ should:
        </p>
        <ToggleMultiple
          options={overwriteDuplicatesOptions}
          value={overwriteDuplicates ? 'true' : 'false'}
          onChange={handleDuplicateOptionChange}
          enabled={uploadBoxState === 'enabled'}
          className="mb-3"
        />
        <FileUploadBox
          state={uploadBoxState}
          onChange={handleFileChange}
          message={uploadBoxMessage}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          inputRef={inputFileRef}
        />
      </Step>
      {uploadError && isErrorMessageExpanded && (
        <div className="my-4">
          <Alert
            className="flex-column p-4 pb-3"
            variant="danger"
            onClose={() => setIsErrorMessageExpanded(false)}
            dismissible
          >
            <Alert.Heading className="text-lg">
              There was a problem with importing referrals from
              <br />"{uploadError.fileName}" file:
            </Alert.Heading>

            {uploadError.message.split('\n').map((line, index) => (
              <p key={index} className="my-1">
                {line}
              </p>
            ))}
          </Alert>
        </div>
      )}
      {uploadError && !isErrorMessageExpanded && (
        <div className="my-4 text-end">
          <Button
            variant="link"
            onClick={() => setIsErrorMessageExpanded(true)}
          >
            Show last error
          </Button>
        </div>
      )}
      {/* STEP 4 - confirm upload */}
      {file && uploadStatus === 'idle' && (
        <Modal show onHide={() => handleFileCancel()}>
          <Modal.Header closeButton closeLabel="Close">
            <Modal.Title>Confirm upload</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Import referrals from "<b>{file.name}</b>" file?
            </p>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn btn-outline-secondary me-2"
              onClick={() => {
                handleFileCancel()
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                handleUpload()
              }}
            >
              Upload
            </button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={isShowingFinishedModal} onHide={hideFinishedModal}>
        <Modal.Header closeButton closeLabel="Close">
          <FontAwesomeIcon icon={faInfoCircle} size="lg" className="me-2" />{' '}
          <Modal.Title>We have received your historical referrals</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Please note your referrals won't appear immediately. You will receive
          an email confirmation once a member of the RQ team has reviewed and
          processed your request.
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" onClick={hideFinishedModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const overwriteDuplicatesOptions: ToggleOption[] = [
  {
    label: (
      <p className="m-0">
        <b>Update</b> with imported data.
      </p>
    ),
    value: 'true',
  },
  {
    label: (
      <p className="m-0">
        <b>Keep existing</b> data.
      </p>
    ),
    value: 'false',
  },
]

export default UploadGeneral
