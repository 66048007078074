import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../../hooks/useCurrentCompanyOrFail'
import {
  setOpen,
  setStep,
} from '../../../../../redux/slices/referralsImportHistorical'
import ImportHistoricalReferralsModal from '../../../../modals/ImportHistoricalReferralsModal/ImportHistoricalReferralsModal'
import ImportHistoricalReferralsTypeChoice from '../../../../modals/ImportHistoricalReferralsModal/ImportHistoricalReferralsTypeChoice'

const ImportHistoricalReferrals: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentCompany = useCurrentCompanyOrFail()
  const currentCompanyHasPanel = currentCompany.hasPanel ?? false

  function goToStep(stepName: 'upload-1' | 'upload-2') {
    // Make sure the modal's showing (sometimes it's ont yet)
    dispatch(setOpen(true))

    // Actually go to the step
    dispatch(setStep(stepName))
  }

  return (
    <>
      {currentCompanyHasPanel ? (
        <ImportHistoricalReferralsTypeChoice />
      ) : (
        // There's only one option, so just show a basic button
        // (we don't need the extra info to inform the choice)
        <button
          onClick={() => goToStep('upload-2')}
          type="button"
          className="w-100 invite-type-button px-4 py-5 rounded-3 col d-flex flex-column align-items-center gap-2"
        >
          <FontAwesomeIcon icon={faSuitcase} size="3x" />
          Import your historical referrals
        </button>
      )}

      <ImportHistoricalReferralsModal />
    </>
  )
}

export default ImportHistoricalReferrals
