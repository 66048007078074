import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import {
  faCheckCircle,
  faPlusCircle,
  faTriangleExclamation,
} from '@rq-ratings/pro-solid-svg-icons'
import classNames from 'classnames'
import React, { ReactNode, useEffect, useState, useTransition } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useNotyf from '../../../hooks/useNotyf'
import {
  DEFAULT_BULK_IMPORT_CIRCUMSTANCES,
  IMPORT_FILE_NOTIFICATION_DURATION,
} from '../../../lib/constants'
import queryClient from '../../../lib/queryClient'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import {
  bulkUpdateAdvisor,
  bulkUpdateServiceArea,
  removeImportItem,
  selectReferralsImportHistorical,
  updateImportItem,
} from '../../../redux/slices/referralsImportHistorical'
import ActionButton from '../../misc/ActionButton'
import AdvisorCell from '../../misc/ImportReferralsComponents/AdvisorCell'
import ClientCell from '../../misc/ImportReferralsComponents/ClientCell'
import ImportErrorMessage from '../../misc/ImportReferralsComponents/ImportErrorMessage'
import ReferralDateCell from '../../misc/ImportReferralsComponents/ReferralDateCell'
import ServiceCell from '../../misc/ImportReferralsComponents/ServiceCell'
import StatusCell from '../../misc/ImportReferralsComponents/StatusCell'
import Step from '../../misc/Step'
import { useImportHistoricalReferralMutation } from './useImportHistoricalReferralMutation'

interface Props {
  darkMode?: boolean
  startOverButton: ReactNode
  handleFinish: () => void
  className?: string
}

const ResolveImportHistorical: React.FC<Props> = ({
  darkMode = false,
  startOverButton,
  handleFinish,
  className = '',
}) => {
  const notyf = useNotyf()
  const referralsImportHistoricalState = useAppSelector(
    selectReferralsImportHistorical,
  )
  const { mutateAsync: importHistoricalReferralsAsyncMutation } =
    useImportHistoricalReferralMutation()
  const [notifyStatus, setNotifyStatus] = useState<boolean>(false)
  const [isImporting, startImport] = useTransition()

  const importedCount = referralsImportHistoricalState.importItems.filter(
    (importItem) => importItem.importStatus === 'imported',
  ).length
  const totalCount = referralsImportHistoricalState.importItems.length

  useEffect(() => {
    if (notifyStatus) {
      notyf.success({
        message:
          importedCount === 1
            ? `Referral successfully processed`
            : `${importedCount} referrals successfully processed (of ${totalCount})`,
        duration: IMPORT_FILE_NOTIFICATION_DURATION,
      })
      setNotifyStatus(false)
    }
  }, [notifyStatus, importedCount, notyf, totalCount])

  const isFinished = importedCount == totalCount

  if (referralsImportHistoricalState.importItems.length === 0) {
    return <div>No import items</div>
  }

  const processImport = async () => {
    const mutations = []
    for (const importItem of referralsImportHistoricalState.importItems) {
      if (
        importItem.importStatus === 'ready' ||
        importItem.importStatus === 'error'
      ) {
        mutations.push(
          importHistoricalReferralsAsyncMutation(importItem, {
            // It looks like TanStackQuery is firing onSuccess once, not on every item.
            // It works well in this case of invalidating referrals cache.
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ['referrals'],
              })
            },
          }),
        )
      }
    }
    try {
      await Promise.all(mutations)
    } catch (error) {
      notyf.errorWithoutReporting({
        message: 'Some referrals failed to import',
        duration: IMPORT_FILE_NOTIFICATION_DURATION,
      })
    }
    setNotifyStatus(true)
  }

  return (
    <>
      <Modal.Body className={' ' + className}>
        <Step step="3">
          <p className="mb-2">Review import data.</p>
          <table
            className={classNames(
              'DataTable table table-bordered table-vertically-aligned DataTable mb-0 text-sm mb-0 ',
              darkMode ? 'table-dark' : '',
            )}
            style={{ height: 'fit-content' }}
          >
            <thead>
              <tr>
                <th>Client</th>
                <th style={{ width: '25%' }}>Partner / Advisor</th>
                <th style={{ width: '25%' }}>Primary Service</th>
                <th>Circumstances</th>
                <th style={{ width: '20%' }}>Status</th>
                <th style={{ width: '140px' }}>Date Referred</th>
                <th style={{ width: '70px' }}>Import Status</th>
              </tr>
            </thead>
            <tbody>
              {referralsImportHistoricalState.importItems.map(
                (importItem, index) => (
                  <ImportRow
                    importItem={importItem}
                    key={importItem.refUUID}
                    index={index}
                  />
                ),
              )}
            </tbody>
          </table>
          <p className="mt-2 text-muted text-sm">
            <FontAwesomeIcon icon={faInfoCircle} /> You can update some fields
            above, move your mouse over the field and click the value or
            "change" button.
          </p>
        </Step>
        <Step step="4">
          <p className="mb-2">Import referrals.</p>
        </Step>
      </Modal.Body>
      <Modal.Footer>
        {startOverButton}
        <div className="flex-grow-1"></div>
        <span>
          {importedCount} / {totalCount} imported
        </span>
        {isFinished ? (
          <Button variant="success" onClick={() => handleFinish()}>
            Finish
          </Button>
        ) : (
          <ActionButton
            isProcessing={isImporting}
            variant="primary"
            onClick={() => {
              startImport(() => {
                processImport()
              })
            }}
          >
            Import
          </ActionButton>
        )}
      </Modal.Footer>
    </>
  )
}

export default ResolveImportHistorical

function ImportRow({
  importItem,
  index,
}: {
  importItem: ReferralsImportItem
  index: number
}) {
  const dispatch = useAppDispatch()

  const isError = importItem.importStatus === 'error'
  const cellClassName = isError
    ? 'table-danger'
    : index % 2
      ? 'table-striped-bg'
      : ''

  return (
    <>
      <tr>
        <td className={cellClassName}>
          <ClientCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
          />
        </td>
        <td className={cellClassName}>
          {importItem.toCompany.name}
          <br />
          <AdvisorCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
            bulkUpdateAdvisors={(toCompany, toCompanyUser) =>
              dispatch(bulkUpdateAdvisor({ toCompany, toCompanyUser }))
            }
          />
        </td>
        <td className={cellClassName}>
          <ServiceCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
            bulkUpdateServiceArea={(toCompany, serviceArea) =>
              dispatch(bulkUpdateServiceArea({ toCompany, serviceArea }))
            }
            withExempt
          />
        </td>
        <td className={cellClassName}>
          {importItem.sourceCircumstances ?? DEFAULT_BULK_IMPORT_CIRCUMSTANCES}
        </td>
        <td className={cellClassName}>
          <StatusCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
          />
        </td>
        <td className={cellClassName}>
          <ReferralDateCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
          />
        </td>
        <td
          className={`${cellClassName} position-relative`}
          rowSpan={isError ? 2 : 1}
        >
          {importItem.importStatus === 'ready' && (
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="text-info"
              size="2x"
            />
          )}
          {importItem.importStatus === 'processing' && (
            <Spinner size="sm" className="text-info" />
          )}
          {importItem.importStatus === 'imported' && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-success"
              size="2x"
            />
          )}
          {importItem.importStatus === 'error' && (
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="text-info"
              size="2x"
            />
          )}
          {(importItem.importStatus === 'ready' ||
            importItem.importStatus === 'error') && (
            <>
              <a
                href="#"
                className="text-danger position-absolute"
                style={{ right: '5px', top: '5px' }}
                onClick={() => {
                  if (confirm('Are you sure you want to remove this item?')) {
                    dispatch(removeImportItem(importItem.refUUID))
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </a>
            </>
          )}
        </td>
      </tr>
      {isError && (
        <tr>
          <td
            colSpan={6}
            className="table-danger text-danger position-relative"
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="position-absolute"
              size="2x"
              style={{ top: '10px', left: '15px' }}
            />
            {importItem.importErrors ? (
              <ul className="ms-3">
                {Object.entries(importItem.importErrors).map(
                  ([key, value], index) => (
                    <li key={`${index}-${key}-${value}`}>
                      <ImportErrorMessage
                        importItem={importItem}
                        field={key}
                        message={value}
                      />
                    </li>
                  ),
                )}
              </ul>
            ) : (
              <p>Unknown error</p>
            )}
          </td>
        </tr>
      )}
    </>
  )
}
