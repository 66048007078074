import React from 'react'
import { useRouteError } from 'react-router'

// This is just a dummy component that will rethrow the error caught by
// react-router so that our error boundary can catch it.
const RootError: React.FC = () => {
  const error = useRouteError()

  throw error
}

export default RootError
