import classNames from 'classnames'
import React, { CSSProperties } from 'react'

import {
  useCurrentCompanyServicesQuery,
  useInvalidateCurrentCompanyServicesQuery,
} from '../../../../../../../hooks/useCompanyServicesQuery'
import useCurrentCompanyOrFail from '../../../../../../../hooks/useCurrentCompanyOrFail'
import useMaxWidth from '../../../../../../../hooks/useMaxWidth'
import { ServiceAreaItem } from '../../../../../../../types/responses/service-area'
import CompanyServiceToggleButton from './CompanyServiceToggleButton'

interface Props {
  /**
   * Results to show in this section
   */
  results: ServiceAreaItem[]
  headingText: string
  /**
   * Including results shown in other sections
   */
  totalResultsLength: number
  /**
   * The term that was searched to bring about these results
   */
  searchTerm: string
}

const ResultsSection: React.FC<Props> = ({
  results,
  headingText,
  totalResultsLength,
  searchTerm,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const isMaxWidthMd = useMaxWidth('md')
  const invalidateCompanyServicesQuery =
    useInvalidateCurrentCompanyServicesQuery()

  const companyServicesQuery = useCurrentCompanyServicesQuery()
  const companyServices = companyServicesQuery.data

  if (results.length === 0) {
    return null
  }

  function getServiceIdByServiceAreaId(
    serviceAreaId: number,
  ): number | undefined {
    if (!companyServices) {
      return
    }

    return companyServices.find(
      (companyService) => companyService.serviceArea.id === serviceAreaId,
    )?.id
  }

  return (
    <div
      className={classNames('align-self-stretch pt-3', {
        'd-flex flex-column': isMaxWidthMd,
      })}
      style={
        {
          // Even columns. Ideally max 10 per column. Definitely max 3 columns
          '--number-of-columns': Math.min(Math.ceil(totalResultsLength / 8), 3),
          display: 'grid',
          gridTemplateColumns:
            'repeat(var(--number-of-columns), minmax(auto, 1fr))',
          gap: '.5rem',
        } as CSSProperties
      }
    >
      <h2 style={{ gridColumn: '1 / -1', textWrap: 'balance' }} className="h4">
        {headingText}
      </h2>

      {results.map((serviceArea: ServiceAreaItem) => (
        <CompanyServiceToggleButton
          key={serviceArea.id}
          companyId={currentCompany.id}
          serviceId={getServiceIdByServiceAreaId(serviceArea.id)}
          serviceAreaId={serviceArea.id}
          serviceAreaName={serviceArea.name}
          onChangeSaved={() => {
            invalidateCompanyServicesQuery()
          }}
          searchTerm={searchTerm}
        />
      ))}
    </div>
  )
}

export default ResultsSection
