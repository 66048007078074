import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import serviceService from '../../../../../../../lib/services/serviceService'
import ActionButton from '../../../../../../misc/ActionButton'
import { useDeleteServiceMutation } from '../../hooks/useDeleteServiceMutation'

interface Props {
  companyId: number
  serviceId?: number
  serviceAreaId: number
  serviceAreaName: string
  onChangeSaved: () => void
  searchTerm: string
}

const CompanyServiceToggleButton: React.FC<Props> = ({
  companyId,
  serviceId,
  serviceAreaId,
  serviceAreaName,
  onChangeSaved,
  searchTerm,
}) => {
  const [isSelected, setIsSelected] = useState(serviceId !== undefined)

  // Watch for changes
  useEffect(() => {
    setIsSelected(serviceId !== undefined)
  }, [serviceId])

  const createServiceMutation = useMutation({
    mutationFn: async () => {
      setIsSelected(true)

      return serviceService.createService({
        company: `/v1/companies/${companyId}`,
        serviceArea: `/v1/service-areas/${serviceAreaId}`,
      })
    },
    onSuccess: () => {
      onChangeSaved()
    },
    onError: () => {
      setIsSelected(false)
    },
  })

  const deleteServiceMutation = useDeleteServiceMutation({
    onMutate: () => setIsSelected(false),
    onSuccess: onChangeSaved,
    onError: () => setIsSelected(true),
  })

  // I.e. the prop passed doesn't reflect what we now think the state should be
  // which will be because we're waiting to re-query in the parent component
  const isWaitingForSync = (serviceId !== undefined) !== isSelected

  function tokeniseAndFormatServiceAreaName() {
    let result = serviceAreaName

    const searchWords = searchTerm.split(' ')

    const escapedSearchWords = searchWords.map((searchWord) =>
      searchWord.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'),
    )

    const regEx = new RegExp(`(${escapedSearchWords.join('|')})`, 'ig')

    result = result.replaceAll(
      regEx,
      '<strong style="color: inherit;">$1</strong>',
    )

    return result
  }

  return (
    <ActionButton
      variant={isSelected ? 'primary' : 'light'}
      size="lg"
      className="fs-5 overflow-hidden rounded-pill"
      style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      isProcessing={
        createServiceMutation.isPending ||
        deleteServiceMutation.isPending ||
        isWaitingForSync
      }
      showIsProcessingText={false}
      onClick={() => {
        if (isSelected && serviceId !== undefined) {
          deleteServiceMutation.mutate(serviceId)
        } else {
          createServiceMutation.mutate()
        }
      }}
    >
      <span
        dangerouslySetInnerHTML={{ __html: tokeniseAndFormatServiceAreaName() }}
      ></span>
    </ActionButton>
  )
}

export default CompanyServiceToggleButton
