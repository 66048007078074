import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AddServiceFeeFormValues } from '../../components/misc/CommercialAgreementsForm/components/AddServiceFeeModal/constants'
import {
  COMMERCIAL_AGREEMENT_FIELDS,
  COMMERCIAL_AGREEMENT_TYPE_OPTION,
} from '../../components/misc/CommercialAgreementsForm/constants'
import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../lib/constants'
import { getOrFail } from '../../lib/helpers/helperFunctions'
import commercialAgreementService from '../../lib/services/commercialAgreementService'
import {
  CommercialAgreementReferralDirection,
  CommercialAgreementType,
  SerializableFile,
} from '../../types/misc'
import { RootState } from '../store'

export type CommercialAgreementsFormState = {
  referralDirection: CommercialAgreementReferralDirection
  form: CommercialAgreementsFormValues
  hasCreatedOutgoingAgreement: boolean
}

export interface CommercialAgreementsFormValues {
  hasCommercialAgreement?: boolean
  outgoingAgreement: CommercialAgreementDirectionInput
  incomingAgreement: CommercialAgreementDirectionInput
}

export interface CommercialAgreementDirectionInput {
  type?: CommercialAgreementTypeOption
  existingAgreementFile?: SerializableFile | null
  serviceFees: AddServiceFeeFormValues[]
  consentLetterApprovedAt?: string
  consentLetterAmendRequest?: string
  commissionJustification?: string
  hasEditedCommissionJustification?: boolean
  isRqHandleFeeSharing?: boolean
}

export interface ServiceFeeInput {
  serviceArea?: string
  serviceAreaOther: string
  feeCategory: string
  feeCategoryOther: string
  shareType: string
  shareValue?: number
  shareValueLow?: number
  shareValueHigh?: number
  duration?: string
  notes: string
  example: string
  passBackFee?: boolean
  passBackFeeNotes?: string
  isFromCompany: boolean
  willAddAnother: boolean
}

export type CommercialAgreementTypeOption = CommercialAgreementType | 'LATER'

export const COMMERCIAL_AGREEMENT_DIRECTION_INITIAL_INPUT: CommercialAgreementDirectionInput =
  {
    serviceFees: [],
    existingAgreementFile: null,
    type: COMMERCIAL_AGREEMENT_TYPE_OPTION.LATER,
    hasEditedCommissionJustification: false,
  }

export const INITIAL_COMMERCIAL_AGREEMENTS_FORM_STATE: CommercialAgreementsFormState =
  {
    referralDirection: COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING,
    form: {
      outgoingAgreement: COMMERCIAL_AGREEMENT_DIRECTION_INITIAL_INPUT,
      incomingAgreement: COMMERCIAL_AGREEMENT_DIRECTION_INITIAL_INPUT,
    },
    hasCreatedOutgoingAgreement: false,
  }

export const commercialAgreementsForm = createSlice({
  name: 'commercialAgreementsForm',
  initialState: INITIAL_COMMERCIAL_AGREEMENTS_FORM_STATE,
  reducers: {
    setReferralDirection(
      state,
      action: PayloadAction<CommercialAgreementsFormState['referralDirection']>,
    ) {
      state.referralDirection = action.payload
    },

    setHasCreatedOutgoingAgreement(
      state,
      action: PayloadAction<
        CommercialAgreementsFormState['hasCreatedOutgoingAgreement']
      >,
    ) {
      state.hasCreatedOutgoingAgreement = action.payload
    },

    setCommercialAgreementFormValues(
      state,
      action: PayloadAction<CommercialAgreementsFormState['form']>,
    ) {
      state.form = action.payload
    },

    clearCommercialAgreementFormValues(state) {
      state.form = INITIAL_COMMERCIAL_AGREEMENTS_FORM_STATE.form
    },

    clearCommercialAgreementsForm() {
      return INITIAL_COMMERCIAL_AGREEMENTS_FORM_STATE
    },
  },
})

// Selectors
export const selectCommercialAgreementsForm = (state: RootState) =>
  state.commercialAgreementsForm

export const selectCommercialAgreementsFormValues = createSelector(
  selectCommercialAgreementsForm,
  (state) => state.form,
)

export const selectReferralDirection = createSelector(
  selectCommercialAgreementsForm,
  (state) => state.referralDirection,
)

export const selectHasCreatedOutgoingAgreement = createSelector(
  selectCommercialAgreementsForm,
  (state) => state.hasCreatedOutgoingAgreement,
)

export const selectIsOutgoingAgreement = createSelector(
  selectReferralDirection,
  (direction) => direction === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING,
)

export const selectIsIncomingAgreement = createSelector(
  selectReferralDirection,
  (direction) => direction === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING,
)

export const selectCurrentDirectionValues = createSelector(
  selectCommercialAgreementsFormValues,
  selectReferralDirection,
  (formValues, referralDirection) => {
    return commercialAgreementService.isOutgoingAgreement(referralDirection)
      ? formValues.outgoingAgreement
      : formValues.incomingAgreement
  },
)

export const selectCurrentDirectionAgreementType = createSelector(
  selectCurrentDirectionValues,
  (values) => values.type,
)

export const selectCurrentDirectionAgreementTypeOrFail = createSelector(
  selectCurrentDirectionAgreementType,
  (agreementType) =>
    getOrFail(
      agreementType,
      'Expected current direction agreement type to be set',
    ),
)

export const selectIsFeeSharingAgreement = createSelector(
  selectCurrentDirectionAgreementType,
  (agreementType) =>
    commercialAgreementService.isFeeSharingAgreement(agreementType),
)

export const selectHasAgreementForCurrentDirection = createSelector(
  selectCurrentDirectionAgreementType,
  (type) => commercialAgreementService.hasCommercialAgreement(type),
)

export const selectCurrentAgreementPrefix = createSelector(
  selectIsOutgoingAgreement,
  (isOutgoingAgreement) =>
    isOutgoingAgreement
      ? COMMERCIAL_AGREEMENT_FIELDS.outgoingAgreement
      : COMMERCIAL_AGREEMENT_FIELDS.incomingAgreement,
)

export const selectOutgoingAgreement = createSelector(
  selectCommercialAgreementsFormValues,
  (values) => values.outgoingAgreement,
)

export const selectIncomingAgreement = createSelector(
  selectCommercialAgreementsFormValues,
  (values) => values.incomingAgreement,
)

export const selectHasCommercialAgreement = createSelector(
  selectCommercialAgreementsFormValues,
  (values) => values.hasCommercialAgreement,
)

export const selectHasSkippedCommercialAgreements = createSelector(
  selectHasCommercialAgreement,
  (hasCommercialAgreement) => typeof hasCommercialAgreement === 'undefined',
)

// Actions
export const {
  setReferralDirection,
  setCommercialAgreementFormValues,
  clearCommercialAgreementFormValues,
  clearCommercialAgreementsForm,
} = commercialAgreementsForm.actions

export const commercialAgreementsFormReducer = commercialAgreementsForm.reducer
