import omit from 'lodash/omit'

import {
  INTEGRATION_SERVICE,
  IntegrationService,
  IntellifloCompanyAdviserMapping,
} from '../../types/misc'
import {
  CreateCompanyIntegrationRequest,
  GetIntellifloAdvisersParams,
  UpdateCompanyIntegrationRequest,
} from '../../types/requests/company-integrations'
import {
  CompanyIntegrationCollectionItem,
  IntellifloAdviserCollection,
  IntellifloIntegrationHealthCheck,
  IntellifloIntegrationItem,
  IntellifloIntegrationMetadataItem,
  NylasIntegrationItem,
} from '../../types/responses/company-integrations'
import apiService from './apiService'

class CompanyIntegrationService {
  private endpoint = '/v1/company-integrations'

  public async getCollection(
    companyId: number,
  ): Promise<CompanyIntegrationCollectionItem> {
    const response = await apiService.get(
      `/v1/companies/${companyId}/integrations`,
    )

    return response.data['hydra:member']
  }

  async getIntellifloIntegration(): Promise<IntellifloIntegrationItem> {
    const response = await apiService.get(
      `${this.endpoint}/${INTEGRATION_SERVICE.intelliflo}`,
    )

    return response.data
  }

  async getNylasIntegration(): Promise<NylasIntegrationItem> {
    const response = await apiService.get(
      `${this.endpoint}/${INTEGRATION_SERVICE.nylas}`,
    )

    return response.data
  }

  async createCompanyIntegration(
    integratedService: IntegrationService,
    request: CreateCompanyIntegrationRequest,
  ) {
    const response = await apiService.post(
      `/v1/companies/integrations/${integratedService}/register`,
      request,
    )

    return response.data
  }

  async updateIntellifloIntegrationRequest(
    companyIntegrationId: number,
    request: UpdateCompanyIntegrationRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${companyIntegrationId}`,
      request,
    )

    return response.data
  }

  async enableIntellifloIntegration(): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${INTEGRATION_SERVICE.intelliflo}/enable`,
      {},
    )

    return response.data
  }

  async disableIntellifloIntegration(companyIntegrationId: number) {
    const request: UpdateCompanyIntegrationRequest = {
      enabled: false,
    }

    const response = await apiService.patch(
      `${this.endpoint}/${companyIntegrationId}`,
      request,
    )

    return response.data
  }

  async getIntellifloAdvisers(
    companyIntegrationId: number,
    params?: GetIntellifloAdvisersParams,
  ): Promise<IntellifloAdviserCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${companyIntegrationId}/advisers`,
      { params },
    )

    return response.data
  }

  async getIntellifloIntegrationMetadata(): Promise<IntellifloIntegrationMetadataItem> {
    const response = await apiService.get(
      `${this.endpoint}/${INTEGRATION_SERVICE.intelliflo}/metadata`,
    )

    return response.data
  }

  async getIntellifloHealthCheck(): Promise<IntellifloIntegrationHealthCheck> {
    const response = await apiService.get(
      `${this.endpoint}/${INTEGRATION_SERVICE.intelliflo}/health-check`,
    )

    return response.data
  }

  buildUpdateAdviserMappingRequest(
    args: BuildUpdateAdviserMappingRequestArgs,
  ): UpdateCompanyIntegrationRequest {
    const { integration, companyId, rqUserId, intellifloAdviserId } = args
    const currentAdviserMapping = integration.config.adviserMapping

    const companyAdviserMapping = currentAdviserMapping[companyId] || {}
    const newCompanyAdviserMapping = intellifloAdviserId
      ? { ...companyAdviserMapping, [rqUserId]: intellifloAdviserId }
      : (omit(
          companyAdviserMapping,
          rqUserId,
        ) as IntellifloCompanyAdviserMapping)

    return {
      config: {
        ...integration.config,
        adviserMapping: {
          ...currentAdviserMapping,
          [companyId]: newCompanyAdviserMapping,
        },
      },
    }
  }
}

export interface BuildUpdateAdviserMappingRequestArgs {
  integration: IntellifloIntegrationItem
  companyId: number
  rqUserId: number
  intellifloAdviserId?: number
}

const companyIntegrationService = new CompanyIntegrationService()

export default companyIntegrationService
