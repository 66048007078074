import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  step: string
}

const Step: React.FC<Props> = ({
  step = '1',
  className,
  children,
  ...props
}) => {
  return (
    <div className={classNames('my-4', className)} {...props}>
      <h4 className="text-primary mt-0 mb-1">Step {step}</h4>
      {children}
    </div>
  )
}

export default Step
