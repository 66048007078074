import './CompanyFcaServiceDetailsRow.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faRemove } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { FormControl, Spinner } from 'react-bootstrap'
import invariant from 'tiny-invariant'
import * as yup from 'yup'

import { useCurrentCompanyServicesQuery } from '../../../../../../hooks/useCompanyServicesQuery'
import useCurrentCompanyOrFail from '../../../../../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../../../../../hooks/useNotyf'
import constraintViolationService from '../../../../../../lib/services/constraintViolationService'
import errorTypeService from '../../../../../../lib/services/errorTypeService'
import fcaServiceService from '../../../../../../lib/services/fcaServiceService'
import serviceService from '../../../../../../lib/services/serviceService'
import { CreateOrUpdateFcaServiceRequest } from '../../../../../../types/requests/fca-services'
import {
  FcaServiceTypeItem,
  ServiceAreaItem,
} from '../../../../../../types/responses/common-data'
import { CompanyServiceItem } from '../../../../../../types/responses/companies'
import ActionButton from '../../../../../misc/ActionButton'
import CompleteRegistrationFormikDirtyNavigationGuard from '../../../components/CompleteRegistrationFormikDirtyNavigationGuard'
import SelectFcaServiceAreaField from './fields/SelectFcaServiceAreaField'
import SelectFcaServiceTypeField from './fields/SelectFcaServiceTypeField'

interface Props {
  service: ServiceFormValues
  rowNumber: number
  onSave?: (newService: CompanyServiceItem) => void
  onDelete: () => void
  isEditingByDefault: boolean
}

export interface ServiceFormValues {
  id?: number
  company: IRI
  serviceArea?: ServiceAreaItem
  isOnlyInConjunctionWithFP: boolean
  notes: string
  serviceType?: FcaServiceTypeItem
}

const ServiceFormFields: Record<
  keyof ServiceFormValues,
  keyof ServiceFormValues
> = {
  id: 'id',
  company: 'company',
  serviceArea: 'serviceArea',
  isOnlyInConjunctionWithFP: 'isOnlyInConjunctionWithFP',
  notes: 'notes',
  serviceType: 'serviceType',
}

const CompanyFcaServiceDetailsRow: React.FC<Props> = ({
  service,
  rowNumber,
  onSave,
  onDelete,
  isEditingByDefault,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const notyf = useNotyf()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(isEditingByDefault)

  const companyServicesQuery = useCurrentCompanyServicesQuery()

  const addOrUpdateServiceMutation = useMutation({
    mutationFn: async ({
      values,
    }: {
      values: ServiceFormValues
      formikHelpers: FormikHelpers<ServiceFormValues>
    }) => {
      if (!values.serviceArea) {
        return
      }

      invariant(values.serviceType, 'Expected serviceType to be defined')

      const request: CreateOrUpdateFcaServiceRequest = {
        company: `/v1/companies/${currentCompany.id}`,
        serviceArea: `/v1/service-areas/${values.serviceArea.id}`,
        isStandaloneService: !values.isOnlyInConjunctionWithFP,
        serviceType: values.serviceType['@id'],
        notes: values.notes,
      }

      return values.id
        ? fcaServiceService.updateFcaService(values.id, request)
        : fcaServiceService.createFcaService(request)
    },
    onSuccess: (newService) => {
      if (onSave) {
        onSave(newService)
      }

      setIsEditing(false)
    },
    onError: (error, { formikHelpers }) => {
      if (errorTypeService.isConstraintViolationListError(error)) {
        formikHelpers.setErrors(constraintViolationService.formatErrors(error))
        return
      }

      notyf.error('Something went wrong')
    },
  })

  const deleteServiceMutation = useMutation({
    mutationFn: async (serviceId: number) =>
      serviceService.deleteService(serviceId),
    onSuccess: () => {
      onDelete()
    },
    onError: () => {
      setIsDeleting(false)
    },
  })

  async function handleSubmit(
    values: ServiceFormValues,
    formikHelpers: FormikHelpers<ServiceFormValues>,
  ) {
    await addOrUpdateServiceMutation.mutateAsync({ values, formikHelpers })

    formikHelpers.setSubmitting(false)
  }

  function areStandaloneServicesAllowedByOtherServices(
    excludedServiceId: number | undefined,
  ) {
    if (companyServicesQuery.data === undefined) {
      return false
    }

    for (const service of companyServicesQuery.data) {
      if (service.id === excludedServiceId) {
        continue
      }

      if (service.serviceArea.allowsNonStandaloneServices) {
        return true
      }
    }

    return false
  }

  return (
    <div
      className={`company-service-details-row${isDeleting ? ' deleting' : ''} text-start`}
    >
      {!isEditing && (
        <button
          className="card border p-3 m-0 d-flex flex-row gap-3 text-start"
          onClick={() => setIsEditing(true)}
          title="Edit"
        >
          <div className="flex-grow-1">
            <h2 className="m-0">{service.serviceArea?.name}</h2>

            <p className="m-0">
              {service.isOnlyInConjunctionWithFP
                ? 'Offered with financial planning'
                : 'Offered standalone'}
              {service.serviceType === undefined
                ? ''
                : ` | ${service.serviceType.name}`}
              {service.notes ? (
                <>
                  {' '}
                  | <em>{service.notes}</em>
                </>
              ) : (
                ''
              )}
            </p>
          </div>

          {/* Not a button because we're already inside a button that does the same thing,
          this is just in case it's not clear to the user that they can just click the card */}
          <div
            className="btn btn-lg btn-outline-primary align-self-start align-self-center text-nowrap"
            onClick={() => setIsEditing(true)}
          >
            <FontAwesomeIcon icon={faPencil} className="me-2" />
            Edit
          </div>
        </button>
      )}

      {isEditing && (
        <Formik
          initialValues={service}
          enableReinitialize
          validateOnChange
          validationSchema={yup.object().shape({
            id: yup.number().min(0).nullable(),
            company: yup.string().required('Required'),
            serviceArea: yup.object().shape({
              allowsNonStandaloneServices: yup.bool(),
            }),
            isOnlyInConjunctionWithFP: yup
              .boolean()
              .when(
                'serviceArea',
                ([serviceArea]: ServiceAreaItem[], _schema) =>
                  serviceArea?.allowsNonStandaloneServices ||
                  areStandaloneServicesAllowedByOtherServices(service.id)
                    ? yup.bool()
                    : yup
                        .bool()
                        .isFalse(
                          'You must first add a financial planning service',
                        ),
              ),
            notes: yup.string().nullable(),
            serviceType: yup.object().required('Required'),
          })}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, submitForm }) => {
            return (
              <Form
                className={classNames('card border p-3 m-0 w-100', {
                  'pop-in': !service.id,
                })}
              >
                <CompleteRegistrationFormikDirtyNavigationGuard />

                <div className="row g-3">
                  {/* Service */}
                  <div className="col-lg-4 d-flex flex-column gap-2">
                    <div>
                      <label
                        className="form-label"
                        htmlFor={`company-service-details-is-standalone-service-${rowNumber}`}
                      >
                        Service
                      </label>

                      <Field
                        component={SelectFcaServiceAreaField}
                        name={ServiceFormFields.serviceArea}
                        className={classNames({
                          'is-invalid':
                            errors.serviceArea && touched.serviceArea,
                        })}
                      />

                      {errors.serviceArea && touched.serviceArea ? (
                        <FormControl.Feedback type="invalid">
                          {errors.serviceArea}
                        </FormControl.Feedback>
                      ) : null}
                    </div>

                    {/* Is Standalone */}
                    <div className="form-check">
                      <label
                        className="form-check-label"
                        htmlFor={`company-service-details-is-standalone-service-${rowNumber}`}
                      >
                        Only offered in conjunction with holistic financial
                        planning?
                      </label>

                      <Field
                        className={classNames('form-check-input', {
                          'is-invalid':
                            errors.isOnlyInConjunctionWithFP &&
                            touched.isOnlyInConjunctionWithFP,
                        })}
                        name={ServiceFormFields.isOnlyInConjunctionWithFP}
                        type="checkbox"
                        id={`company-service-details-is-standalone-service-${rowNumber}`}
                      />

                      {errors.isOnlyInConjunctionWithFP &&
                      touched.isOnlyInConjunctionWithFP ? (
                        <FormControl.Feedback type="invalid">
                          {errors.isOnlyInConjunctionWithFP}
                        </FormControl.Feedback>
                      ) : null}
                    </div>
                  </div>

                  {/* Independence status */}
                  <div className="col-lg-4">
                    <label
                      className="form-label"
                      htmlFor={`company-service-details-is-standalone-service-${rowNumber}`}
                    >
                      Independence status
                    </label>

                    <Field
                      component={SelectFcaServiceTypeField}
                      name={ServiceFormFields.serviceType}
                      className={classNames({
                        'is-invalid': errors.serviceType && touched.serviceType,
                      })}
                    />

                    {errors.serviceType && touched.serviceType ? (
                      <FormControl.Feedback type="invalid">
                        {errors.serviceType}
                      </FormControl.Feedback>
                    ) : null}
                  </div>

                  {/* Notes */}
                  <div className="col-lg-4">
                    <label
                      className="form-label"
                      htmlFor={`company-service-details-notes-${rowNumber}`}
                    >
                      Notes
                    </label>

                    <Field
                      className={classNames('form-control', {
                        'is-invalid': errors.notes && touched.notes,
                      })}
                      name={ServiceFormFields.notes}
                      component="textarea"
                      rows={2}
                      id={`company-service-details-notes-${rowNumber}`}
                    />

                    {errors.notes && touched.notes ? (
                      <FormControl.Feedback type="invalid">
                        {errors.notes}
                      </FormControl.Feedback>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex gap-3 justify-content-end align-items-center mt-3">
                  <ActionButton
                    className="d-flex align-items-center"
                    size="lg"
                    variant="outline-danger"
                    onClick={() => {
                      setIsDeleting(true)

                      if (!service.id) {
                        // No ID means this isn't saved yet anyway, so no mutation.
                        // Do it in 300ms to allow a nice transition.
                        setTimeout(() => onDelete(), 300)
                      } else {
                        deleteServiceMutation.mutate(service.id)
                      }
                    }}
                    isProcessing={isDeleting}
                  >
                    <FontAwesomeIcon icon={faRemove} className="me-2" />
                    Remove
                  </ActionButton>

                  <ActionButton
                    isProcessing={isSubmitting}
                    isProcessingText="Saving"
                    showSpinner={isSubmitting}
                    variant="success"
                    size="lg"
                    onClick={() => {
                      if (!isSubmitting) {
                        submitForm()
                      }
                    }}
                  >
                    Save
                    {isSubmitting && <Spinner className="ms-2" />}
                  </ActionButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}

      {isDeleting && (
        <div className="overlay">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default CompanyFcaServiceDetailsRow
