import fileDownload from 'js-file-download'
import React, { useRef, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'

import { ImportReferralsError } from '../../../contexts/ImportReferralsContext'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useImportReferralsContext from '../../../hooks/useImportReferralsContext'
import { IMPORT_FILE_NOTIFICATION_DURATION } from '../../../lib/constants'
import apiService from '../../../lib/services/apiService'
import {
  selectReferralsImportHistorical,
  setOnClientDuplicate,
} from '../../../redux/slices/referralsImportHistorical'
import { selectCurrentCompanyOrFail } from '../../../redux/slices/session'
import { BulkImportReferralCollection } from '../../../types/responses/referral-import'
import { FileUploadBox, FileUploadBoxState } from '../../misc/FileUploadBox'
import Step from '../../misc/Step'
import { ToggleMultiple, ToggleOption } from '../../misc/ToggleMultiple'

interface Props {
  onSuccessfulUpload: (data: BulkImportReferralCollection) => void
  className?: string
}

const UploadHistorical: React.FC<Props> = ({
  onSuccessfulUpload,
  className = '',
}) => {
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)
  const {
    uploadStatus,
    uploadError,
    setFile,
    setUploadStatus,
    setUploadError,
  } = useImportReferralsContext()
  const dispatch = useAppDispatch()
  const referralsImportHistoricalState = useAppSelector(
    selectReferralsImportHistorical,
  )

  const [isErrorMessageExpanded, setIsErrorMessageExpanded] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)

  let uploadBoxMessage = 'Ready to upload a list of your referrals?'
  let uploadBoxState: FileUploadBoxState = 'enabled'
  if (uploadStatus === 'uploading') {
    uploadBoxState = 'uploading'
    uploadBoxMessage = 'Uploading...'
  } else if (uploadStatus === 'success') {
    uploadBoxState = 'success'
    uploadBoxMessage = 'Data has been uploaded successfully'
  } else if (uploadStatus === 'error') {
    uploadBoxState = 'error'
    uploadBoxMessage = 'Upload failed'
  }

  // handlers
  const handleTemplateDownload = async () => {
    const fileName = 'import-historical-referrals-template.xlsx'
    const response = await apiService.get(
      `v1/companies/${currentCompany.id}/download-import-historical-referrals-template`,
      {
        responseType: 'blob',
      },
    )
    fileDownload(response.data, fileName)
  }
  const handleDuplicateOptionChange = (value: string) => {
    dispatch(
      setOnClientDuplicate(value === 'true' ? 'update' : 'keep-original'),
    )
  }
  const handleFileCancel = () => {
    setFile(null)

    if (inputFileRef.current?.value) {
      inputFileRef.current.value = ''
    }

    setUploadStatus('idle')
  }
  const handleUpload = async (file: File) => {
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append('file', file)

    setFile(file)
    setUploadStatus('uploading')
    setUploadError(null)
    setIsErrorMessageExpanded(false)

    apiService
      .post(
        `v1/companies/${currentCompany.id}/process-import-historical-referrals`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((response) => {
        // Handle success
        handleFileCancel()
        setUploadStatus('success')

        setTimeout(() => {
          setUploadStatus('idle')
        }, IMPORT_FILE_NOTIFICATION_DURATION)

        onSuccessfulUpload(response.data)
      })
      .catch((error) => {
        setUploadStatus('error')

        const uploadError: ImportReferralsError = {
          message: error.response.data['hydra:description'] ?? '',
          fileName: file.name,
        }

        if (uploadError.message === '') {
          uploadError.message = error.response.statusText ?? 'Unknown error'
        }

        setUploadError(uploadError)
        setIsErrorMessageExpanded(true)

        setTimeout(() => {
          handleFileCancel()
        }, IMPORT_FILE_NOTIFICATION_DURATION)
      })
  }

  return (
    <div className={' ' + className}>
      <Step step="1" className="mt-0">
        <p className="mb-2">
          Download our template spreadsheet and fill in your historical
          referrals.
        </p>
        <button
          className="btn d-block btn-primary"
          onClick={() => handleTemplateDownload()}
        >
          Download template
        </button>
      </Step>
      <Step step="2">
        <p className="mb-2">Upload the completed spreadsheet.</p>
        <p className="mb-2">
          If your import contains duplicated clients, RQ should:
        </p>
        <ToggleMultiple
          options={overwriteDuplicatesOptions}
          value={
            referralsImportHistoricalState.onClientDuplicate == 'update'
              ? 'true'
              : 'false'
          }
          onChange={handleDuplicateOptionChange}
          enabled={uploadBoxState === 'enabled'}
          className="mb-3"
        />
        <FileUploadBox
          state={uploadBoxState}
          onChange={handleUpload}
          message={uploadBoxMessage}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          inputRef={inputFileRef}
        />
      </Step>
      <Step step="3">
        <p className="mb-2">Review import data.</p>
      </Step>
      <Step step="4">
        <p className="mb-2">Import referrals.</p>
      </Step>
      {uploadError && isErrorMessageExpanded && (
        <div className="my-4">
          <Alert
            className="flex-column p-4 pb-3"
            variant="danger"
            onClose={() => setIsErrorMessageExpanded(false)}
            dismissible
          >
            <Alert.Heading className="text-lg">
              There was a problem with importing referrals from
              <br />"{uploadError.fileName}" file:
            </Alert.Heading>

            {uploadError.message.split('\n').map((line, index) => (
              <p key={index} className="my-1">
                {line}
              </p>
            ))}
          </Alert>
        </div>
      )}
      {uploadError && !isErrorMessageExpanded && (
        <div className="my-4 text-end">
          <Button
            variant="link"
            onClick={() => setIsErrorMessageExpanded(true)}
          >
            Show last error
          </Button>
        </div>
      )}
    </div>
  )
}

const overwriteDuplicatesOptions: ToggleOption[] = [
  {
    label: (
      <p className="m-0">
        <b>Update</b> with imported data.
      </p>
    ),
    value: 'true',
  },
  {
    label: (
      <p className="m-0">
        <b>Keep existing</b> data.
      </p>
    ),
    value: 'false',
  },
]

export default UploadHistorical
