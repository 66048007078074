import { ErrorPayload } from 'vite'

import pageReloadService from './services/pageReloadService'

export function registerGlobalEventListeners() {
  handleRuntimeError()
  handleVitePreloadError()
  pageReloadService.trackProgrammaticReload()
  disableNumberInputs()
}

// Show an error overlay when a runtime error occurs (only applies on dev)
function handleRuntimeError() {
  window.addEventListener('error', ({ error }) => showErrorOverlay(error))
  window.addEventListener('unhandledrejection', ({ reason }) =>
    showErrorOverlay(reason),
  )

  function showErrorOverlay(error: ErrorPayload['err']) {
    const ErrorOverlay = customElements.get('vite-error-overlay')

    if (!ErrorOverlay) {
      return
    }

    console.error(error)
    const overlay = new ErrorOverlay(error)
    document.body.appendChild(overlay)
  }
}

// If we fail to load a dynamic import, reload the page
// https://vitejs.dev/guide/build#load-error-handling
function handleVitePreloadError() {
  window.addEventListener('vite:preloadError', () => {
    console.log('vite:preloadError occurred.')

    if (pageReloadService.isProgrammaticReload()) {
      console.log(
        'Already reloaded page programmatically. Not reloading again as this ' +
          'might cause infinite reloads.',
      )
      return
    }

    console.log('Reloading the page...')
    pageReloadService.reloadPage()
  })
}

// Disable scrolling on input[type="number"] elements
// https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
function disableNumberInputs() {
  document.addEventListener('wheel', function () {
    const activeElement = document.activeElement

    const isNumberInput =
      activeElement instanceof HTMLInputElement &&
      activeElement.type === 'number'

    if (isNumberInput) {
      activeElement.blur()
    }
  })
}
