import React from 'react'
import { useLocation } from 'react-router'

import reduceChildRoutes from './reduceChildRoutes'
import { SidebarPage } from './Sidebar'

interface Props {
  depth: number
  pages: SidebarPage[]
}

const SidebarNavList: React.FC<Props> = ({ depth, pages }) => {
  const location = useLocation()
  const currentRoute = location.pathname

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    [] as JSX.Element[],
  )

  return <>{childRoutes}</>
}

export default SidebarNavList
