import { cloneDeep } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import ServiceMissingWarning from './ServiceMissingWarning'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
  bulkUpdateServiceArea: (
    toCompany: ReferralsImportItem['toCompany'],
    serviceArea: ReferralsImportItem['serviceArea'],
  ) => void
  withExempt?: boolean
}

const ServiceCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateServiceArea,
  withExempt = false,
}) => {
  const [isEdit, setEdit] = useState(false)
  const isSet = !!importItem.serviceArea

  const serviceAreaOptions: SelectableOption[] =
    useMemo((): SelectableOption[] => {
      return importItem.toCompany.serviceAreas
        .map((serviceArea) => {
          return {
            label: serviceArea.name,
            value: serviceArea.id,
          }
        })
        .filter(
          (value, index, array) =>
            array.findIndex((a) => a.value == value.value) === index,
        )
    }, [importItem])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newImportItem = cloneDeep(importItem)
    newImportItem.serviceArea =
      importItem.toCompany.serviceAreas.find(
        (serviceArea) => serviceArea.id === +event.target.value,
      ) || null
    newImportItem.serviceAreaDefaultUsed = false
    updateImportItem(newImportItem)
    setEdit(false)
  }

  const handleBulkApply = () => {
    bulkUpdateServiceArea(importItem.toCompany, importItem.serviceArea)
  }

  const Exempt = withExempt ? (
    <>
      <p className="text-muted text-xs mt-1 mb-none">
        Exempt regulated activity:{' '}
        <span className="font-weight-700">
          {importItem.isEraOverride ? 'Yes' : 'no'}
        </span>
      </p>
    </>
  ) : (
    <></>
  )

  const Warning =
    importItem.serviceAreaDefaultUsed && importItem.sourceServiceAreaId ? (
      <ServiceMissingWarning
        importItem={importItem}
        afterBlockElement={withExempt}
      />
    ) : null

  return (
    <>
      {!isEdit && isSet ? (
        <>
          <span onClick={() => setEdit(true)} className="cursor-pointer">
            {importItem.serviceArea?.name || ''}
            {importItem.serviceAreaDefaultUsed && (
              <>
                <span className="text-warning text-xs"> (default)</span>
              </>
            )}
          </span>
          {Exempt}
          {Warning}
          <span className="show-on-row-hover text-xs">
            {(!withExempt || !!Warning) && ', '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setEdit(true)}
            >
              change
            </Button>
            {importItem.bulkCompanyCount > 1 && (
              <>
                {' or '}
                <Button
                  variant="link"
                  size="sm"
                  className="text-xs p-0 m-0"
                  onClick={handleBulkApply}
                >
                  apply&nbsp;this&nbsp;service&nbsp;to&nbsp;all&nbsp;referrals&nbsp;for&nbsp;this&nbsp;company&nbsp;(
                  {importItem.bulkCompanyCount})
                </Button>
              </>
            )}
          </span>
        </>
      ) : (
        <>
          <Form.Select
            onChange={handleChange}
            value={importItem.serviceArea?.id}
          >
            {importItem.serviceArea?.name || <option>Select service...</option>}
            {serviceAreaOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Form.Select>
          {Exempt}
          {!importItem.serviceArea && (
            <ServiceMissingWarning importItem={importItem} />
          )}
        </>
      )}
    </>
  )
}

interface SelectableOption {
  label: string
  value: number
}
export default ServiceCell
