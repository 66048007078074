import * as React from 'react'
import { CheckCircle, XCircle } from 'react-feather'

export type FileUploadBoxState =
  | 'disabled'
  | 'enabled'
  | 'uploading'
  | 'success'
  | 'error'

interface Props {
  onChange: (file: File) => void
  accept?: string
  state: FileUploadBoxState
  message?: string
  minHeight?: string
  inputRef?: React.RefObject<HTMLInputElement>
}

export const FileUploadBox: React.FC<Props> = ({
  onChange,
  state = 'disabled',
  accept = '*/*',
  message = undefined,
  minHeight = '200px',
  inputRef = undefined,
}: Props) => {
  const isEnabled = state === 'enabled'

  return (
    <div
      className={
        'border rounded bg-white position-relative d-flex flex-column align-items-center justify-content-center' +
        (state === 'enabled' ? ' hover-border-primary' : '') +
        (state === 'success' ? ' border-success' : '') +
        (state === 'error' ? ' border-danger' : '')
      }
      style={{ minHeight: minHeight }}
    >
      {(state === 'disabled' || state === 'enabled') && (
        <svg
          width="72"
          height="58"
          viewBox="0 0 72 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M26.9505 34.2593L25.2491 32.5448L35.9955 21.7154L46.7419 32.5448L45.0405 34.2593L37.1906 26.3488V58H34.7847V26.3488L26.9505 34.2593ZM58.8387 19.3205C58.8387 19.1209 58.8657 18.9372 58.8657 18.7535C58.8657 8.39133 50.5386 0 40.2558 0C32.8582 0 26.4846 4.36576 23.4891 10.6729C22.195 10.022 20.7322 9.63869 19.186 9.63869C14.4329 9.63869 10.4944 13.1245 9.73368 17.6898C4.07802 19.6471 0 25.047 0 31.4108C0 39.4189 6.44111 45.9119 14.3901 45.9119H30.0045V43.4875H14.3901C7.77794 43.4875 2.40585 38.0581 2.40585 31.3972C2.40585 26.2377 5.66917 21.6451 10.5214 19.9578L11.8717 19.4906L12.1103 18.0731C12.7022 14.5714 15.6819 12.0359 19.1996 12.0359C20.3248 12.0359 21.4074 12.3058 22.4201 12.8161L24.6144 13.9205L25.6699 11.6957C28.3436 6.06897 34.0668 2.42442 40.2558 2.42442C49.1883 2.42442 56.4599 9.75209 56.4599 18.7535C56.4599 18.7966 56.4599 18.8533 56.4464 18.91C56.4329 19.0234 56.4329 19.1504 56.4329 19.2774L56.3766 21.7449L58.823 21.7585C64.7577 21.7721 69.5964 26.6618 69.5964 32.6446C69.5964 38.6115 64.7712 43.4875 58.85 43.517H41.9978V45.9414H58.8635C66.1215 45.8983 72 39.9609 72 32.6446C71.9865 25.2874 66.1058 19.3341 58.8342 19.3205H58.8387Z"
            fill={state === 'enabled' ? '#0D96FF' : '#C4C4C4'}
          />
        </svg>
      )}
      {state === 'uploading' && (
        <div
          className="spinner-border text-primary"
          role="status"
          style={{ height: 48, width: 48 }}
        ></div>
      )}
      {state === 'success' && (
        <CheckCircle className="text-success" size={48} />
      )}
      {state === 'error' && <XCircle className="text-danger" size={48} />}

      {message && <p className="mt-3 mb-0 text-gray text-sm">{message}</p>}
      <input
        type="file"
        accept={accept}
        disabled={!isEnabled}
        className={
          'position-absolute d-block h-100 w-100 opacity-0 top-0 start-0 ' +
          (isEnabled ? 'cursor-pointer' : 'pointer-events-none')
        }
        ref={inputRef}
        onChange={(e) => {
          if (e.target.files === null) {
            return
          }
          const file = e.target.files[0]
          if (file) {
            onChange(file)
          }
        }}
      />
    </div>
  )
}
