import { useQuery, useQueryClient } from '@tanstack/react-query'

import { QUERY_KEYS } from '../lib/queryKeys'
import companyService from '../lib/services/companyService'
import useCurrentCompanyOrFail from './useCurrentCompanyOrFail'

export function useCurrentCompanyServicesQuery() {
  const currentCompany = useCurrentCompanyOrFail()

  return useQuery({
    queryKey: QUERY_KEYS.companyServices(currentCompany.id),
    queryFn: () => companyService.getCompanyServices(currentCompany.id),
  })
}

export function useInvalidateCurrentCompanyServicesQuery() {
  const queryClient = useQueryClient()
  const currentCompany = useCurrentCompanyOrFail()

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyServices(currentCompany.id),
    })
  }
}
