import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faInfoCircle,
} from '@rq-ratings/pro-regular-svg-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Formik, FormikProps } from 'formik'
import React, { useRef, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router'
import * as yup from 'yup'

import stripeSvg from '../assets/img/brands/stripe.svg'
import MaskedInput from '../components/form/MaskedInput'
import TextInput from '../components/form/TextInput'
import CardPartial from '../components/layouts/partials/CardPartial'
import ActionButton from '../components/misc/ActionButton'
import InvalidOrExpiredCard from '../components/misc/InvalidOrExpiredCard'
import CardHeading from '../components/typography/CardHeading'
import LoadingWidget from '../components/widgets/LoadingWidget'
import useNotyf from '../hooks/useNotyf'
import { DATE_FORMATS } from '../lib/constants'
import { isDevelopment } from '../lib/helpers/envHelpers'
import {
  formatDate,
  isConstraintViolationListError,
  normalizeDateOfBirth,
} from '../lib/helpers/helperFunctions'
import { yupSchemas } from '../lib/helpers/yupSchemas'
import clientService from '../lib/services/clientService'
import constraintViolationService from '../lib/services/constraintViolationService'
import { FormErrors } from '../types/misc'
import { CreateClientPaymentRequest } from '../types/requests/clients'
import FillClientPaymentButton from './FillClientPaymentButton'
import FormGroup from './referClient/steps/4_ClientDetails/components/FormGroup'

export interface ClientPaymentFormValues {
  dateOfBirth: string
  addressLine1: string
  city: string
  postcode: string
  accountNumber: string
  sortCode: string
}

const ClientPaymentPage: React.FC = () => {
  const notyf = useNotyf()
  const [stripeError, setStripeError] = useState<string | null>(null)

  const ClientPaymentFormFields: Record<
    keyof ClientPaymentFormValues,
    keyof ClientPaymentFormValues
  > = {
    dateOfBirth: 'dateOfBirth',
    addressLine1: 'addressLine1',
    city: 'city',
    postcode: 'postcode',
    accountNumber: 'accountNumber',
    sortCode: 'sortCode',
  }

  const formikRef = useRef<FormikProps<ClientPaymentFormValues>>(null)

  const validationSchema = yup.object().shape({
    [ClientPaymentFormFields.accountNumber]: yupSchemas.number().required(),
    [ClientPaymentFormFields.sortCode]: yup.string().required(),
    [ClientPaymentFormFields.dateOfBirth]: yupSchemas.dayMonthYear().required(),
    [ClientPaymentFormFields.addressLine1]: yup.string().required(),
    [ClientPaymentFormFields.city]: yup.string().required(),
    [ClientPaymentFormFields.postcode]: yup.string().required(),
  })

  const { id: clientId, code } = useParams() as {
    id: string
    code: string
  }

  const clientPaymentQuery = useQuery({
    queryKey: ['confirm-client-payment', code],
    queryFn: () => clientService.getClientPaymentByToken(+clientId, code),
    refetchOnWindowFocus: false,
    retry: false,
  })

  const submitPaymentDetailsMutation = useMutation({
    mutationFn: (values: ClientPaymentFormValues) => {
      const request: CreateClientPaymentRequest = {
        client: {
          dateOfBirth: normalizeDateOfBirth(values.dateOfBirth || ''),
          addressLine1: values.addressLine1,
          city: values.city,
          postcode: values.postcode,
        },
        accountNumber: values.accountNumber,
        sortCode: values.sortCode,
      }

      return clientService.submitPaymentDetailsForClient(
        +clientId,
        code,
        request,
      )
    },
    onError: (error) => {
      if (isConstraintViolationListError(error)) {
        const errors = constraintViolationService.formatErrors(error)
        formikRef.current?.setErrors(
          // Remove client. prefix from errors so it can map to form fields
          Object.keys(errors).reduce((errors: FormErrors, key) => {
            errors[key.replace('client.', '')] = errors[key] as string
            return errors
          }, {}),
        )

        // Check for stripe error
        if (errors.stripe) {
          setStripeError(errors.stripe)
        }

        return
      }

      notyf.error('Something went wrong')
    },
  })

  if (clientPaymentQuery.isLoading) {
    return <LoadingWidget variant="white" />
  }

  if (!clientPaymentQuery.data || clientPaymentQuery.isError) {
    return <InvalidOrExpiredCard />
  }

  const clientPayment = clientPaymentQuery.data

  const MessageWrapper = ({ children }: { children?: React.ReactNode }) => {
    return (
      <Container fluid className="p-0">
        {children}
      </Container>
    )
  }

  if (submitPaymentDetailsMutation.isSuccess) {
    return (
      <CardPartial
        wrapper={<MessageWrapper />}
        header="Payment details submitted"
        variant="success"
        message={`
              Thank you ${clientPayment.client.firstName} for submitting your
              payment details. You can now receive funds from ${clientPayment.company.name}.
            `}
      />
    )
  }

  if (clientPayment.isSetup) {
    return (
      <CardPartial
        wrapper={<MessageWrapper />}
        header="It looks like you've already submitted your payment details"
        variant="warning"
        message={`
              We already have payment details on file for ${clientPayment.client.fullName}. You can receive funds from ${clientPayment.company.name}.
            `}
      />
    )
  }

  return (
    <>
      <Helmet title="Add your payment information" />

      <Container fluid className="p-0">
        <Card>
          <Card.Body>
            <CardHeading className="mb-5">
              Payment details for {clientPayment.client.fullName}
            </CardHeading>
            {clientPayment.company.logoUrl && (
              <>
                <img
                  className="img-fluid float-md-start me-3 mb-4 mb-md-0"
                  src={clientPayment.company.logoUrl}
                  alt={clientPayment.company.name}
                  style={{ width: '150px' }}
                />
              </>
            )}

            <Formik
              innerRef={formikRef}
              initialValues={{
                dateOfBirth: clientPayment.client.dateOfBirth
                  ? formatDate(
                      clientPayment.client.dateOfBirth,
                      DATE_FORMATS.DAY_MONTH_YEAR,
                    )
                  : '',
                addressLine1: clientPayment.client.addressLine1 || '',
                city: clientPayment.client.city || '',
                postcode: clientPayment.client.postcode || '',
                // Test numbers here https://stripe.com/docs/testing?locale=en-GB&testing-method=payment-methods&payment-method=bacs-direct-debit
                accountNumber: '',
                sortCode: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => submitPaymentDetailsMutation.mutate(values)}
              enableReinitialize
            >
              <Form>
                <Row>
                  <Col>
                    <p>
                      Hello {clientPayment.client.firstName}, so that we can
                      send you funds please provide your payment details below:
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <MaskedInput
                        label="Sort Code"
                        name={ClientPaymentFormFields.sortCode}
                        placeholder="00-11-22"
                        maskOptions={{
                          blocks: [2, 2, 2],
                          numericOnly: true,
                          delimiter: '-',
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <MaskedInput
                        label="Account Number"
                        name={ClientPaymentFormFields.accountNumber}
                        placeholder="00118855"
                        maskOptions={{
                          blocks: [8],
                          numericOnly: true,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {stripeError && (
                  <Row>
                    <Col>
                      <Alert variant="danger">
                        <div className="alert-icon my-auto">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            fixedWidth
                            size="lg"
                          />
                        </div>
                        <div className="alert-message">{stripeError}</div>
                      </Alert>
                    </Col>
                  </Row>
                )}

                <div className="d-flex flex-row-reverse align-items-center">
                  <img
                    className="img-fluid fs-0"
                    src={stripeSvg}
                    alt="Stripe"
                    style={{ width: '100px' }}
                  />
                  <div className="fst-italic small text-end">
                    All payment information is securely processed and stored by
                    Stripe
                    <br />
                    By registering your details, you agree to the{' '}
                    <Link
                      to="https://stripe.com/connect-account/legal/recipient"
                      target="_blank"
                    >
                      Stripe Recipient Agreement
                    </Link>
                    .
                  </div>
                </div>

                <hr />

                <Alert variant="info" className="mt-4 col-md-12">
                  <div className="alert-icon my-auto">
                    <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="lg" />
                  </div>
                  <div className="alert-message ">
                    We're required to request the following additional
                    information to verifiy your identity
                  </div>
                </Alert>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <MaskedInput
                        label="Date of birth"
                        name={ClientPaymentFormFields.dateOfBirth}
                        placeholder="dd/mm/yyyy"
                        maskOptions={{
                          date: true,
                          datePattern: ['d', 'm', 'Y'],
                          delimiter: '/',
                        }}
                        useFormattedValue={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <TextInput
                        label="Address Line 1"
                        name={ClientPaymentFormFields.addressLine1}
                        placeholder="1 High Street"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <TextInput
                        label="City"
                        name={ClientPaymentFormFields.city}
                        placeholder="London"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <TextInput
                        label="Postcode"
                        name={ClientPaymentFormFields.postcode}
                        placeholder="WC2H 9JQ"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ActionButton
                  className="mt-4 float-end"
                  size="lg"
                  type="submit"
                  isProcessing={submitPaymentDetailsMutation.isPending}
                >
                  Submit
                </ActionButton>

                {isDevelopment() && <FillClientPaymentButton />}
              </Form>
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default ClientPaymentPage
