import React from 'react'

import useNotyf from '../../../hooks/useNotyf'
import { usePermissions } from '../../../hooks/usePermissions'
import { ROUTES } from '../../../lib/routes'
import { StepName } from './InviteToRqModal'
import InviteTypeButton from './InviteTypeButton'

interface Props {
  onSelect: (newStepName?: StepName) => void
}

const InviteTypeChoice: React.FC<Props> = ({ onSelect }) => {
  const { canManageUsers } = usePermissions()
  const notyf = useNotyf()

  return (
    <div className="row gap-3">
      <InviteTypeButton
        iconName="barChart"
        text="Invite your referral partners to RQ"
        onClick={() => onSelect('referralPartners')}
      />

      <InviteTypeButton
        iconName="groupOfPeople"
        text="Invite a colleague to join your account on RQ"
        href={ROUTES.manageUsers + '?invite-colleague'}
        onClick={() =>
          canManageUsers
            ? onSelect()
            : notyf.errorWithoutReporting(
                'You must be an admin to invite colleagues. <br />Please contact your account admins.',
              )
        }
        className={canManageUsers ? '' : 'opacity-50'}
      />
    </div>
  )
}

export default InviteTypeChoice
