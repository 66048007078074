import { useQuery, useQueryClient } from '@tanstack/react-query'

import { QUERY_KEYS } from '../lib/queryKeys'
import companyIntegrationService from '../lib/services/companyIntegrationService'

export function useNylasCompanyIntegrationQuery() {
  return useQuery({
    queryKey: QUERY_KEYS.companyNylasIntegration,
    queryFn: () => companyIntegrationService.getNylasIntegration(),
    refetchOnWindowFocus: false,
  })
}

export function useInvalidateNylasCompanyIntegrationQuery() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyNylasIntegration,
    })
  }
}
