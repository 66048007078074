import { FieldProps } from 'formik'
import React from 'react'

import useAppSelector from '../../../../../../../hooks/useAppSelector'
import { selectFcaServiceAreas } from '../../../../../../../redux/slices/commonData'
import Select from '../../../../../../select/Select'

interface Props extends FieldProps {
  className: string
}

const SelectFcaServiceAreaField: React.FC<Props> = ({
  field,
  form,
  className,
}) => {
  const serviceAreas = useAppSelector(selectFcaServiceAreas)

  const options = serviceAreas.map((serviceArea) => ({
    value: serviceArea,
    label: serviceArea.name,
  }))

  return (
    <Select
      className={className}
      options={options}
      name={field.name}
      value={options.find(
        (option) => field.value && option.value['@id'] === field.value['@id'],
      )}
      onChange={(option) => form.setFieldValue(field.name, option?.value)}
      onBlur={field.onBlur}
      // important because this opens inside an overflow: hidden
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  )
}

export default SelectFcaServiceAreaField
